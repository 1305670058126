import { Font } from '@react-pdf/renderer';
import PlayfairDisplayUrlPlayfairDisplayitalic400 from './fonts/PlayfairDisplay/PlayfairDisplay-italic-400.ttf?url';
import PlayfairDisplayUrlPlayfairDisplayitalic500 from './fonts/PlayfairDisplay/PlayfairDisplay-italic-500.ttf?url';
import PlayfairDisplayUrlPlayfairDisplayitalic600 from './fonts/PlayfairDisplay/PlayfairDisplay-italic-600.ttf?url';
import PlayfairDisplayUrlPlayfairDisplayitalic700 from './fonts/PlayfairDisplay/PlayfairDisplay-italic-700.ttf?url';
import PlayfairDisplayUrlPlayfairDisplayitalic800 from './fonts/PlayfairDisplay/PlayfairDisplay-italic-800.ttf?url';
import PlayfairDisplayUrlPlayfairDisplayitalic900 from './fonts/PlayfairDisplay/PlayfairDisplay-italic-900.ttf?url';
import PlayfairDisplayUrlPlayfairDisplaynormal400 from './fonts/PlayfairDisplay/PlayfairDisplay-normal-400.ttf?url';
import PlayfairDisplayUrlPlayfairDisplaynormal500 from './fonts/PlayfairDisplay/PlayfairDisplay-normal-500.ttf?url';
import PlayfairDisplayUrlPlayfairDisplaynormal600 from './fonts/PlayfairDisplay/PlayfairDisplay-normal-600.ttf?url';
import PlayfairDisplayUrlPlayfairDisplaynormal700 from './fonts/PlayfairDisplay/PlayfairDisplay-normal-700.ttf?url';
import PlayfairDisplayUrlPlayfairDisplaynormal800 from './fonts/PlayfairDisplay/PlayfairDisplay-normal-800.ttf?url';
import PlayfairDisplayUrlPlayfairDisplaynormal900 from './fonts/PlayfairDisplay/PlayfairDisplay-normal-900.ttf?url';

type FontWeight = 'normal' | 'bold' | 'light' | undefined;
type FontStyle = 'normal' | 'italic' | 'oblique' | undefined;

Font.register({
  family: 'PlayfairDisplay',
  fonts: [
    {
      src: PlayfairDisplayUrlPlayfairDisplayitalic400,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '400' as FontWeight,
    },
    {
      src: PlayfairDisplayUrlPlayfairDisplayitalic500,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '500' as FontWeight,
    },
    {
      src: PlayfairDisplayUrlPlayfairDisplayitalic600,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '600' as FontWeight,
    },
    {
      src: PlayfairDisplayUrlPlayfairDisplayitalic700,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '700' as FontWeight,
    },
    {
      src: PlayfairDisplayUrlPlayfairDisplayitalic800,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '800' as FontWeight,
    },
    {
      src: PlayfairDisplayUrlPlayfairDisplayitalic900,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '900' as FontWeight,
    },
    {
      src: PlayfairDisplayUrlPlayfairDisplaynormal400,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '400' as FontWeight,
    },
    {
      src: PlayfairDisplayUrlPlayfairDisplaynormal500,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '500' as FontWeight,
    },
    {
      src: PlayfairDisplayUrlPlayfairDisplaynormal600,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '600' as FontWeight,
    },
    {
      src: PlayfairDisplayUrlPlayfairDisplaynormal700,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '700' as FontWeight,
    },
    {
      src: PlayfairDisplayUrlPlayfairDisplaynormal800,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '800' as FontWeight,
    },
    {
      src: PlayfairDisplayUrlPlayfairDisplaynormal900,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '900' as FontWeight,
    },
  ],
});
