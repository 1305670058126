import {
  ActionIcon,
  Button,
  Group,
  Modal,
  Paper,
  Stack,
  Text,
  Textarea,
  TextInput,
  Timeline,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import {
  IconBuildingSkyscraper,
  IconCheck,
  IconPlus,
  IconTrash,
  IconX,
} from '@tabler/icons-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IResumeJSONExperience,
  IResumeWithSuggestion,
} from '../../models/Resume';
import { useUpdateResumeJsonMutation } from '../../queries/ResumeQueries';

import classes from './Experience.module.css';
import { ExperienceSection } from './ExperienceSection';

export const Experience: React.FC<{
  resume: IResumeWithSuggestion;
  hidden: boolean;
}> = ({ resume, hidden }) => {
  const experiences = resume.suggestion.experiences;
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const [currentExperience, setCurrentExperience] =
    useState<IResumeJSONExperience | null>(null);
  const [editedExperience, setEditedExperience] =
    useState<IResumeJSONExperience>({
      start: '',
      end: '',
      title: '',
      summary: [''],
      company: { name: '', location: '' },
    });
  const [confirmDeleteOpened, setConfirmDeleteOpened] = useState(false);
  const [experienceToDelete, setExperienceToDelete] = useState<number | null>(
    null,
  );

  const form = useForm({
    initialValues: editedExperience,
    validate: {
      title: (value) => (!value ? t('resume.experience.titleRequired') : null),
      start: (value) =>
        !value ? t('resume.experience.startDateRequired') : null,
      company: {
        name: (value) =>
          !value ? t('resume.experience.companyNameRequired') : null,
      },
      summary: (value) =>
        value.length === 0 || value.some((item) => !item)
          ? t('resume.experience.summaryRequired')
          : null,
    },
  });

  const updateResumeMutation = useUpdateResumeJsonMutation(resume.id);

  const handleEditClick = (experience: IResumeJSONExperience) => {
    setCurrentExperience(experience);
    setEditedExperience({ ...experience });
    form.setValues({ ...experience });
    setOpened(true);
  };

  const handleSave = () => {
    if (form.isValid()) {
      const updatedExperiences = currentExperience
        ? experiences.map((exp) =>
            exp === currentExperience ? form.values : exp,
          )
        : [...experiences, form.values];
      const updatedResumeJson = {
        ...resume.suggestion,
        experiences: updatedExperiences,
      };
      updateResumeMutation.mutate(updatedResumeJson, {
        onSuccess: () => {
          setOpened(false);
        },
      });
    }
  };

  const handleSummaryChange = (index: number, value: string) => {
    const newSummary = [...form.values.summary];
    newSummary[index] = value;
    form.setFieldValue('summary', newSummary);
    form.validateField('summary');
  };

  const addBulletPoint = () => {
    form.setFieldValue('summary', [...form.values.summary, '']);
  };

  const removeBulletPoint = (index: number) => {
    if (form.values.summary.length > 1) {
      const newSummary = form.values.summary.filter((_, idx) => idx !== index);
      form.setFieldValue('summary', newSummary);
    }
  };

  const addExperience = () => {
    setCurrentExperience(null);
    form.reset();
    setOpened(true);
  };

  const confirmRemoveExperience = (index: number) => {
    setExperienceToDelete(index);
    setConfirmDeleteOpened(true);
  };

  const handleRemoveExperience = () => {
    if (experienceToDelete !== null && experiences.length > 1) {
      const updatedExperiences = experiences.filter(
        (_, idx) => idx !== experienceToDelete,
      );
      const updatedResumeJson = {
        ...resume.suggestion,
        experiences: updatedExperiences,
      };
      updateResumeMutation.mutate(updatedResumeJson);
      setConfirmDeleteOpened(false);
    } else {
      showNotification({
        title: t('resume.experience.cannotDelete'),
        message: t('resume.experience.cannotDeleteLastExperience'),
        color: 'red',
      });
    }
  };

  const moveExperienceUp = (index: number) => {
    if (index > 0) {
      const newExperiences = [...experiences];
      [newExperiences[index - 1], newExperiences[index]] = [
        newExperiences[index],
        newExperiences[index - 1],
      ];
      const updatedResumeJson = {
        ...resume.suggestion,
        experiences: newExperiences,
      };
      updateResumeMutation.mutate(updatedResumeJson);
    }
  };

  const moveExperienceDown = (index: number) => {
    if (index < experiences.length - 1) {
      const newExperiences = [...experiences];
      [newExperiences[index], newExperiences[index + 1]] = [
        newExperiences[index + 1],
        newExperiences[index],
      ];
      const updatedResumeJson = {
        ...resume.suggestion,
        experiences: newExperiences,
      };
      updateResumeMutation.mutate(updatedResumeJson);
    }
  };

  return (
    <Stack>
      <Group justify="space-between">
        <Title order={3}>{t('resume.experience.title')}</Title>
        <Button
          leftSection={<IconPlus size={16} />}
          onClick={addExperience}
          variant="outline"
          size="xs"
          disabled={hidden}
        >
          {t('resume.experience.addExperience')}
        </Button>
      </Group>
      <Paper p="lg">
        <Timeline active={experiences.length}>
          {experiences.map((section, index) => (
            <Timeline.Item
              className={classes.hoverHighlight}
              key={index}
              title={
                <Group justify="space-between">
                  <Title order={4}>{section.title}</Title>
                  <Title order={4}>
                    {section.start}
                    {section.end && ` - ${section.end}`}
                  </Title>
                </Group>
              }
              bullet={<IconBuildingSkyscraper size={18} />}
            >
              <ExperienceSection
                section={section}
                index={index}
                hidden={hidden}
                onEdit={() => {
                  handleEditClick(section);
                }}
                onRemove={() => {
                  confirmRemoveExperience(index);
                }}
                onMoveUp={
                  index > 0
                    ? () => {
                        moveExperienceUp(index);
                      }
                    : undefined
                }
                onMoveDown={
                  index < experiences.length - 1
                    ? () => {
                        moveExperienceDown(index);
                      }
                    : undefined
                }
              />
            </Timeline.Item>
          ))}
        </Timeline>
      </Paper>

      <Modal
        opened={opened}
        onClose={() => {
          setOpened(false);
        }}
        title={t('resume.experience.editExperience')}
        centered
        size="xl"
      >
        <form onSubmit={form.onSubmit(handleSave)}>
          <Stack gap="md">
            <TextInput
              label={t('resume.experience.jobTitle')}
              {...form.getInputProps('title')}
              onBlur={() => form.validateField('title')}
            />
            <TextInput
              label={t('resume.experience.companyName')}
              {...form.getInputProps('company.name')}
              onBlur={() => form.validateField('company.name')}
            />
            <TextInput
              label={t('resume.experience.companyLocation')}
              {...form.getInputProps('company.location')}
              onBlur={() => form.validateField('company.location')}
            />
            <TextInput
              label={t('resume.experience.startDate')}
              {...form.getInputProps('start')}
              onBlur={() => form.validateField('start')}
            />
            <TextInput
              label={t('resume.experience.endDate')}
              {...form.getInputProps('end')}
              onBlur={() => form.validateField('end')}
            />
            <div>
              <Title order={5} mt="md">
                {t('resume.experience.summary')}
              </Title>
              {form.values.summary.map((bullet, index) => (
                <Group key={index} align="flex-start" mt="xs">
                  <Textarea
                    value={bullet}
                    onChange={(event) => {
                      handleSummaryChange(index, event.currentTarget.value);
                    }}
                    autosize
                    minRows={1}
                    style={{ flex: 1 }}
                  />
                  {form.values.summary.length > 1 && (
                    <ActionIcon
                      color="red"
                      onClick={() => {
                        removeBulletPoint(index);
                      }}
                    >
                      <IconTrash />
                    </ActionIcon>
                  )}
                </Group>
              ))}
              <Button
                mt="md"
                leftSection={<IconPlus />}
                onClick={addBulletPoint}
              >
                {t('resume.experience.addBulletPoint')}
              </Button>
            </div>
          </Stack>
          <Group justify="flex-end" mt="md" gap="sm">
            <Button
              variant="outline"
              onClick={() => {
                setOpened(false);
              }}
            >
              <IconX />
              {t('labels.cancel')}
            </Button>
            <Button type="submit" disabled={!form.isValid()}>
              <IconCheck />
              {t('labels.save')}
            </Button>
          </Group>
        </form>
      </Modal>

      <Modal
        opened={confirmDeleteOpened}
        onClose={() => {
          setConfirmDeleteOpened(false);
        }}
        title={t('resume.experience.confirmDelete')}
        centered
      >
        <Text>{t('resume.experience.confirmDeleteMessage')}</Text>
        <Group justify="flex-end" mt="md">
          <Button
            variant="outline"
            onClick={() => {
              setConfirmDeleteOpened(false);
            }}
          >
            <IconX />
            {t('labels.cancel')}
          </Button>
          <Button color="red" onClick={handleRemoveExperience}>
            <IconTrash />
            {t('labels.delete')}
          </Button>
        </Group>
      </Modal>
    </Stack>
  );
};
