import {
  Accordion,
  Anchor,
  Image,
  Paper,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ApiRoutes } from '../../ApiRoutes';
import { IResume, JobAdType, MAX_JOB_AD_LENGTH } from '../../models/Resume';
import { TextAccordionPanel } from './TextAccordionPanel';

export const InstructionsSection: React.FC<{ resume: IResume }> = ({
  resume,
}) => {
  const { t } = useTranslation();

  return (
    <Stack>
      <Title order={3}>{t('view.instructions')}</Title>

      <Accordion defaultValue="jobad" variant="contained">
        <Accordion.Item value="jobad">
          <Accordion.Control>
            <Text fw={600}>{t('view.jobAd')}</Text>
          </Accordion.Control>

          {resume.jobAdType === JobAdType.URL ? (
            <Accordion.Panel>
              <Paper p="lg" withBorder shadow="md">
                <Anchor
                  component={Link}
                  to={resume.jobAdUrl || ''}
                  target="_blank"
                >
                  {resume.jobAdType === JobAdType.URL ? (
                    <Image
                      src={`/api/${ApiRoutes.Resumes}/${resume.id}/screenshot`}
                    />
                  ) : (
                    <Text>{resume.jobAdUrl}</Text>
                  )}
                </Anchor>
              </Paper>
            </Accordion.Panel>
          ) : (
            <TextAccordionPanel text={resume.ad} max={MAX_JOB_AD_LENGTH} />
          )}
        </Accordion.Item>

        <Accordion.Item value="info">
          <Accordion.Control>
            <Text fw={600}>{t('view.additionalInfo')}</Text>
          </Accordion.Control>
          <TextAccordionPanel text={resume.info} max={500} />
        </Accordion.Item>
      </Accordion>
    </Stack>
  );
};
