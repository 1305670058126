import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PDFProps, TemplateType } from '../PDFProps';
import {
  BulletPoints,
  BulletPointsWithPadding,
  createStyles,
  DEFAULT_HEADER_FONT,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_TEXT_FONT,
  ResumePDF,
} from '../ResumePDF';
import { generateBackgroundColor } from '../ResumePDF/CreateResumePDFStyles';

import previewPng from './preview-sidebar.png';
import previewWebP from './preview-sidebar.webp';

const specificStyles = (
  primaryColor: string,
  headerFont: string,
  textFont: string,
) =>
  StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#FAFAFA',
      padding: 0,
      fontSize: 12,
      fontFamily: textFont,
    },
    sidebar: {
      width: '30%',
      backgroundColor: generateBackgroundColor(primaryColor, '#ffffff'),
      color: '#FFFFFF',
      padding: 20,
    },
    main: {
      width: '70%',
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 24,
      paddingBottom: 32,
      fontSize: 10,
      fontFamily: textFont,
      backgroundColor: '#FAFAFA',
    },
    header: {
      fontSize: 28,
      textAlign: 'left',
      marginBottom: 20,
      fontFamily: headerFont,
      fontWeight: 'bold',
      color: '#333',
    },
    subHeader: {
      fontSize: 16,
      marginBottom: 10,
      fontFamily: headerFont,
      fontWeight: 'bold',
      color: '#555',
    },
    sidebarHeader: {
      fontSize: 14,
      fontWeight: 'bold',
      marginBottom: 10,
      color: '#FFFFFF',
      marginTop: 20,
      fontFamily: headerFont,
    },
    sidebarText: {
      fontSize: 10, // Adjusted font size for sidebar text
      marginBottom: 5,
      color: '#FFFFFF',
      fontFamily: textFont,
    },
    educationNotes: {
      fontSize: 9,
    },
    BulletPointsWithPaddingymbol: {
      width: 10,
      fontSize: 12,
      marginRight: 5,
      marginTop: 2,
    },
    bulletPointText: {
      flex: 1,
      fontFamily: textFont,
    },
    skillText: {
      fontSize: 10,
      color: '#FFFFFF',
      fontFamily: textFont,
    },
    headerPadding: {
      height: 20,
    },
  });

export const ResumeSideBarPDF: React.FC<PDFProps> = ({
  resume,
  user,
  title,
  language,
  primaryColor = DEFAULT_PRIMARY_COLOR,
  pageSize = DEFAULT_PAGE_SIZE,
  headerFont = DEFAULT_HEADER_FONT,
  textFont = DEFAULT_TEXT_FONT,
}) => {
  const { t } = useTranslation(undefined, { lng: language });

  const styles = useMemo(
    () => ({
      ...createStyles(primaryColor || '#2E3A47', headerFont, textFont),
      ...specificStyles(primaryColor || '#2E3A47', headerFont, textFont),
    }),
    [primaryColor, headerFont, textFont],
  );

  return (
    <ResumePDF
      user={user}
      title={title}
      language={language}
      primaryColor={primaryColor}
      pageSize={pageSize}
      headerFont={headerFont}
      textFont={textFont}
      customStyles={styles}
    >
      <View style={styles.sidebar}>
        <View fixed style={styles.headerPadding}></View>
        <Text style={styles.sidebarHeader}>{t('resume.contact')}</Text>
        <Text style={styles.sidebarText}>{user.profile?.phone}</Text>
        <Text style={styles.sidebarText}>{user.profile?.displayEmail}</Text>
        <Text style={styles.sidebarText}>{user.profile?.address}</Text>
        <Text style={styles.sidebarText}>{user.profile?.website}</Text>

        {Array.isArray(resume.educations) && resume.educations.length > 0 && (
          <Text style={styles.sidebarHeader}>
            {t('resume.education.title')}
          </Text>
        )}
        {resume.educations.map((edu, index) => (
          <View key={index} style={styles.education}>
            <Text style={styles.sidebarText}>
              {edu.start} - {edu.end}
            </Text>
            <Text style={styles.sidebarText}>{edu.schoolName}</Text>
            <Text style={styles.sidebarText}>
              {edu.fieldOfStudy}
              {edu.degree ? `, ${edu.degree}` : ''}
            </Text>
            <View style={styles.educationNotes}>
              <BulletPointsWithPadding points={edu.notes} styles={styles} />
            </View>
          </View>
        ))}

        <View wrap={true}>
          <Text style={styles.sidebarHeader}>{t('resume.skills.title')}</Text>
          <View style={styles.skillText}>
            <BulletPointsWithPadding points={resume.skills} styles={styles} />
          </View>
        </View>

        {user.profile?.languages && user.profile.languages.length > 0 && (
          <View wrap={false}>
            <Text style={styles.sidebarHeader}>{t('resume.languages')}</Text>
            <View style={styles.skillText}>
              <BulletPointsWithPadding
                points={user.profile.languages.map((lang) => lang.name)}
                styles={styles}
              />
            </View>
          </View>
        )}
      </View>

      <View style={styles.main}>
        <View fixed style={styles.headerPadding}></View>
        <Text style={styles.header}>
          {user.profile?.firstName} {user.profile?.lastName}
        </Text>
        <Text style={styles.subHeader}>{title}</Text>

        <View style={styles.personalInfo}>
          <Text style={styles.subHeader}>{t('resume.introduction')}</Text>
          <Text style={styles.text}>{resume.intro}</Text>
        </View>

        {Array.isArray(resume.experiences) && resume.experiences.length > 0 && (
          <View style={styles.workExperience}>
            <Text style={styles.subHeader}>{t('resume.experience.title')}</Text>
            {resume.experiences.map((job, index) => (
              <View key={index} style={styles.section} wrap={false}>
                <Text style={{ fontFamily: headerFont, fontWeight: 'bold' }}>
                  {job.title} - {job.company.name}{' '}
                  {job.company.location && `(${job.company.location})`}
                </Text>
                <Text style={styles.dates}>
                  {job.start} - {job.end || t('timeline.present')}
                </Text>
                <BulletPoints points={job.summary} styles={styles} />
              </View>
            ))}
          </View>
        )}
      </View>
    </ResumePDF>
  );
};

export const resumeTemplateSideBar: TemplateType = {
  label: 'Side Bar',
  component: ResumeSideBarPDF,
  preview: previewWebP,
  previewFallback: previewPng,
};
