import { StyleSheet, Text, View } from '@react-pdf/renderer';
import '../../util/dayjs-i18n';
import React, { useMemo } from 'react';

import {
  CoverLetterPDF,
  currentDateString,
  DEFAULT_HEADER_FONT,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_TEXT_FONT,
} from '../CoverLetterPDF/CoverLetterPDF';
import { createCoverLetterStyles } from '../CoverLetterStyles';
import { PDFProps, TemplateType } from '../PDFProps';

import previewPng from '../ResumeBasicPDF/preview-basic.png';
import previewWebP from '../ResumeBasicPDF/preview-basic.webp';

const specificCoverLetterStyles = (primaryColor: string, headerFont: string) =>
  StyleSheet.create({
    specificHeader: {
      fontSize: 20,
      textAlign: 'center',
      fontFamily: headerFont,
      fontWeight: 'bold',
      color: primaryColor,
      marginBottom: 10,
    },
    subHeader: {
      fontSize: 12,
      marginBottom: 32,
    },
  });

export const CoverLetterBasicPDF: React.FC<PDFProps> = ({
  user,
  resume,
  title,
  language,
  primaryColor = DEFAULT_PRIMARY_COLOR,
  pageSize = DEFAULT_PAGE_SIZE,
  headerFont = DEFAULT_HEADER_FONT,
  textFont = DEFAULT_TEXT_FONT,
}) => {
  const styles = useMemo(
    () =>
      createCoverLetterStyles(
        primaryColor || '#2F7D7A',
        headerFont,
        textFont,
        specificCoverLetterStyles,
      ),
    [primaryColor, headerFont, textFont],
  );

  const paragraphs = resume.coverLetter?.split('\n\n') || [];

  const date = currentDateString(language);

  return (
    <CoverLetterPDF
      user={user}
      title={title}
      language={language}
      primaryColor={primaryColor}
      pageSize={pageSize}
      headerFont={headerFont}
      textFont={textFont}
      customStyles={styles}
    >
      <View>
        <Text style={styles.specificHeader}>
          {user.profile?.firstName} {user.profile?.lastName}
        </Text>
        <Text style={styles.subHeader}>{title}</Text>
      </View>

      <View style={styles.dateSection}>
        <Text style={styles.text}>{date}</Text>
      </View>

      {paragraphs.map((paragraph, idx) => (
        <View key={idx} style={styles.contentSection}>
          <Text style={styles.text}>{paragraph}</Text>
        </View>
      ))}
    </CoverLetterPDF>
  );
};

export const coverLetterTemplateBasic: TemplateType = {
  label: 'Basic',
  component: CoverLetterBasicPDF,
  preview: previewWebP,
  previewFallback: previewPng,
};
