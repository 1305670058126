import { StyleSheet, Text, View } from '@react-pdf/renderer';
import '../../util/dayjs-i18n';
import React, { useMemo } from 'react';

import {
  CoverLetterPDF,
  createCoverLetterStyles,
  currentDateString,
  DEFAULT_HEADER_FONT,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_TEXT_FONT,
} from '../CoverLetterPDF/CoverLetterPDF';
import { PDFProps, TemplateType } from '../PDFProps';

import previewPng from '../ResumeGraphicPDF/preview-graphic.png';
import previewWebP from '../ResumeGraphicPDF/preview-graphic.webp';

const specificCoverLetterStyles = (
  primaryColor: string,
  headerFont: string,
  textFont: string,
) =>
  StyleSheet.create({
    page: {
      flexDirection: 'column',
      backgroundColor: '#FAFAFA',
      padding: 32,
      fontSize: 12,
      fontFamily: textFont,
    },
    header: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 20,
      padding: 16,
      borderBottomWidth: 1,
      borderBottomColor: primaryColor,
    },
    headerLeft: {
      width: '80%',
      paddingRight: 10,
    },
    headerRight: {
      width: '20%',
      paddingLeft: 10,
    },
    headerText: {
      fontSize: 10,
      fontFamily: textFont,
      color: '#333',
      marginBottom: 5,
    },
    specificHeader: {
      fontSize: 20,
      fontFamily: headerFont,
      fontWeight: 'bold',
      color: primaryColor,
      marginBottom: 10,
      textAlign: 'center',
    },
    subHeader: {
      fontSize: 12,
      fontFamily: headerFont,
      color: '#555',
      marginBottom: 32,
      textAlign: 'center',
    },
    dateSection: {
      marginBottom: 32,
      textAlign: 'right',
    },
    container: {
      padding: 10,
    },
  });

export const CoverLetterGraphicPDF: React.FC<PDFProps> = ({
  user,
  resume,
  title,
  language,
  primaryColor = DEFAULT_PRIMARY_COLOR,
  pageSize = DEFAULT_PAGE_SIZE,
  headerFont = DEFAULT_HEADER_FONT,
  textFont = DEFAULT_TEXT_FONT,
}) => {
  const styles = useMemo(
    () => ({
      ...createCoverLetterStyles(
        primaryColor || '#2F7D7A',
        headerFont,
        textFont,
      ),
      ...specificCoverLetterStyles(
        primaryColor || '#2F7D7A',
        headerFont,
        textFont,
      ),
    }),
    [primaryColor, headerFont, textFont],
  );

  const paragraphs = resume.coverLetter?.split('\n\n') || [];

  const date = currentDateString(language);

  return (
    <CoverLetterPDF
      user={user}
      title={title}
      language={language}
      primaryColor={primaryColor}
      pageSize={pageSize}
      headerFont={headerFont}
      textFont={textFont}
      customStyles={styles}
    >
      <View style={styles.page}>
        <View style={styles.header}>
          <View style={styles.headerLeft}>
            <Text style={styles.headerText}>
              {user.profile?.firstName} {user.profile?.lastName}
            </Text>
            <Text style={styles.headerText}>{user.profile?.phone}</Text>
            <Text style={styles.headerText}>{user.profile?.address}</Text>
            <Text style={styles.headerText}>{user.profile?.displayEmail}</Text>
            <Text style={styles.headerText}>{user.profile?.website}</Text>
          </View>
          <View style={styles.headerRight}>
            <Text style={styles.dateSection}>
              <Text style={styles.headerText}>{date}</Text>
            </Text>
          </View>
        </View>
        <Text style={styles.specificHeader}>
          {user.profile?.firstName} {user.profile?.lastName}
        </Text>
        <Text style={styles.subHeader}>{title}</Text>

        {paragraphs.map((paragraph, idx) => (
          <View key={idx} style={styles.contentSection}>
            <Text style={styles.text}>{paragraph}</Text>
          </View>
        ))}
      </View>
    </CoverLetterPDF>
  );
};

export const coverLetterTemplateGraphic: TemplateType = {
  label: 'Graphic',
  component: CoverLetterGraphicPDF,
  preview: previewWebP,
  previewFallback: previewPng,
};
