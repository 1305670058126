import { Button, Overlay, OverlayProps } from '@mantine/core';
import { IconLockOpen } from '@tabler/icons-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AppRouteURL } from '../../AppRouteURL';
import { isSubscriptionExpired, useUserQuery } from '../../models/User';

export const SubscribeCTAOverlay: React.FC<
  { enabled: boolean } & OverlayProps
> = ({ enabled, ...props }) => {
  const { t } = useTranslation();
  const { data: user } = useUserQuery();

  return (
    enabled && (
      <Overlay
        blur={3}
        radius="sm"
        center
        gradient="linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.3) 100%)"
        zIndex={90}
        {...props}
      >
        <Button
          component={Link}
          to={AppRouteURL.subscribe}
          color="red"
          radius="xl"
          leftSection={<IconLockOpen size={18} />}
        >
          {t(
            isSubscriptionExpired(user)
              ? 'labels.resubscribe'
              : 'labels.subscribeCTA',
          )}
        </Button>
      </Overlay>
    )
  );
};
