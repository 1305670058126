import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PDFProps, TemplateType } from '../PDFProps';
import {
  BulletPoints,
  ContactSection,
  createStyles,
  DEFAULT_HEADER_FONT,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_TEXT_FONT,
  ResumePDF,
} from '../ResumePDF';
import previewGraphicPng from './preview-graphic.png';

import previewGraphicWebP from './preview-graphic.webp';

// Define specific styles for this template
const specificStyles = (
  primaryColor: string,
  headerFont: string,
  textFont: string,
) =>
  StyleSheet.create({
    name: {
      fontSize: 24,
      fontWeight: 'bold',
      color: primaryColor,
      textAlign: 'center',
      marginBottom: 10,
      fontFamily: headerFont,
    },
    title: {
      fontSize: 14,
      color: '#333333',
      textAlign: 'center',
      marginBottom: 20,
      fontFamily: textFont,
    },
    contactText: {
      width: '33%',
      textAlign: 'center',
      color: primaryColor,
      fontFamily: textFont,
      textDecoration: 'none',
    },
    sectionTitle: {
      fontSize: 14,
      fontWeight: 'bold',
      color: primaryColor,
      marginBottom: 10,
      borderBottomWidth: 1,
      borderBottomColor: primaryColor,
      paddingBottom: 3,
      fontFamily: headerFont,
    },
    text: {
      fontSize: 10,
      color: '#333333',
      marginBottom: 5,
      fontFamily: textFont,
    },
    bulletPointSymbol: {
      width: 10,
      fontSize: 10,
      marginRight: 5,
      marginTop: 2,
      color: primaryColor,
    },
    workExperienceHeader: {
      fontSize: 12,
      fontWeight: 'bold',
      color: primaryColor,
      marginBottom: 3,
      fontFamily: headerFont,
    },
    workExperienceSection: {
      marginBottom: 20,
    },
  });

export const ResumeGraphicPDF: React.FC<PDFProps> = ({
  resume,
  user,
  title,
  language,
  primaryColor = DEFAULT_PRIMARY_COLOR,
  pageSize = DEFAULT_PAGE_SIZE,
  headerFont = DEFAULT_HEADER_FONT,
  textFont = DEFAULT_TEXT_FONT,
}) => {
  const styles = useMemo(
    () => ({
      ...createStyles(primaryColor || '#2F7D7A', headerFont, textFont),
      ...specificStyles(primaryColor || '#2F7D7A', headerFont, textFont),
    }),
    [primaryColor, headerFont, textFont],
  );
  const { t } = useTranslation(undefined, { lng: language });

  return (
    <ResumePDF
      user={user}
      title={title}
      language={language}
      primaryColor={primaryColor}
      pageSize={pageSize}
      headerFont={headerFont}
      textFont={textFont}
      customStyles={styles}
    >
      <Text style={styles.name}>
        {user.profile?.firstName} {user.profile?.lastName}
      </Text>
      <Text style={styles.title}>{title}</Text>

      <ContactSection styles={styles} user={user} />

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>
          {t('resume.personalInformation')}
        </Text>
        <Text style={styles.text}>{resume.intro}</Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>{t('resume.skills.title')}</Text>
        <BulletPoints points={resume.skills} styles={styles} />
      </View>

      {Array.isArray(resume.educations) && resume.educations.length > 0 && (
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>{t('resume.education.title')}</Text>
          {resume.educations.map((edu, index) => (
            <View key={index} wrap={false}>
              <Text style={styles.text}>
                {edu.degree} • {edu.fieldOfStudy}
              </Text>
              <Text style={styles.text}>{edu.schoolName}</Text>
              <Text style={styles.text}>
                {edu.start} - {edu.end}
              </Text>
              <BulletPoints points={edu.notes} styles={styles} />
            </View>
          ))}
        </View>
      )}

      {user.profile?.languages && user.profile.languages.length > 0 && (
        <View style={styles.section} wrap={false}>
          <Text style={styles.sectionTitle}>{t('resume.languages')}</Text>
          <BulletPoints
            points={user.profile.languages.map((lang) => lang.name)}
            styles={styles}
          />
        </View>
      )}

      {Array.isArray(resume.experiences) && resume.experiences.length > 0 && (
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>
            {t('resume.experience.title')}
          </Text>
          {resume.experiences.map((job, index) => (
            <View key={index} wrap={false} style={styles.workExperienceSection}>
              <Text style={{ fontFamily: headerFont, fontSize: '14px' }}>
                {job.title} • {job.company.name}
                {job.company.location && ` (${job.company.location})`}
              </Text>
              <Text style={styles.dates}>
                {job.start} - {job.end || t('timeline.present')}
              </Text>
              <BulletPoints points={job.summary} styles={styles} />
            </View>
          ))}
        </View>
      )}
    </ResumePDF>
  );
};

export const resumeTemplateGraphic: TemplateType = {
  label: 'Graphic',
  component: ResumeGraphicPDF,
  preview: previewGraphicWebP,
  previewFallback: previewGraphicPng,
};
