import { Styles, StyleSheet } from '@react-pdf/renderer';
import { colord, extend } from 'colord';
import a11yPlugin from 'colord/plugins/a11y';
import mixPlugin from 'colord/plugins/mix';

extend([mixPlugin, a11yPlugin]);

export function generateBackgroundColor(
  baseColor: string,
  textColor: string,
  targetContrast = 4.5,
): string {
  const bgColor = colord(baseColor);
  const txtColor = colord(textColor);

  if (bgColor.contrast(txtColor) >= targetContrast) {
    return bgColor.toHex();
  }

  const isDarkText = txtColor.isDark();

  // Generate a series of tints or shades
  const steps = 10;
  const colorSteps = isDarkText ? bgColor.tints(steps) : bgColor.shades(steps);

  // Find the first color that meets the contrast requirement
  const suitableColor = colorSteps.find(
    (color) => color.contrast(txtColor) >= targetContrast,
  );

  return suitableColor
    ? suitableColor.toHex()
    : isDarkText
      ? '#ffffff'
      : '#000000';
}

export const createStyles = (
  primaryColor: string,
  headerFont: string,
  textFont: string,
  specificStyles?: Styles,
) =>
  StyleSheet.create({
    page: {
      padding: 40,
      fontSize: 10,
      fontFamily: textFont,
      backgroundColor: '#FAFAFA',
    },
    section: {
      marginBottom: 0,
      padding: 10,
    },
    header: {
      fontSize: 28,
      textAlign: 'center',
      marginBottom: 0,
      fontFamily: headerFont,
      color: primaryColor,
    },
    subHeader: {
      fontSize: 18,
      marginBottom: 16,
      fontFamily: headerFont,
      color: '#666',
      textAlign: 'center',
    },
    text: {
      fontSize: 10,
      marginBottom: 5,
      color: '#666',
      fontFamily: textFont,
    },
    bulletList: {
      marginBottom: 0,
      padding: 0,
    },
    bulletPointContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'flex-start',
      marginBottom: 1,
    },
    bulletPointSymbol: {
      width: 10,
      fontSize: 10,
      color: primaryColor,
    },
    bulletPointText: {
      flex: 1,
      color: '#666',
      fontFamily: textFont,
      fontSize: 10,
      lineHeight: 1.2,
    },
    personalInfo: {
      marginBottom: 20,
    },
    workExperience: {
      marginBottom: 20,
    },
    education: {
      marginBottom: 20,
    },
    skills: {
      marginBottom: 20,
    },
    contact: {
      marginBottom: 20,
    },
    contactSection: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 20,
      fontSize: 10,
      marginHorizontal: 10,
    },
    contactText: {
      textAlign: 'center',
      color: primaryColor,
      fontFamily: textFont,
      textDecoration: 'none',
    },
    dates: {
      color: '#666',
      fontFamily: textFont,
      marginBottom: 10,
    },

    ...specificStyles,
  });
