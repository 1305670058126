import { Font } from '@react-pdf/renderer';
import OpenSansUrlOpenSansitalic300 from './fonts/OpenSans/OpenSans-italic-300.ttf?url';
import OpenSansUrlOpenSansitalic400 from './fonts/OpenSans/OpenSans-italic-400.ttf?url';
import OpenSansUrlOpenSansitalic500 from './fonts/OpenSans/OpenSans-italic-500.ttf?url';
import OpenSansUrlOpenSansitalic600 from './fonts/OpenSans/OpenSans-italic-600.ttf?url';
import OpenSansUrlOpenSansitalic700 from './fonts/OpenSans/OpenSans-italic-700.ttf?url';
import OpenSansUrlOpenSansitalic800 from './fonts/OpenSans/OpenSans-italic-800.ttf?url';
import OpenSansUrlOpenSansnormal300 from './fonts/OpenSans/OpenSans-normal-300.ttf?url';
import OpenSansUrlOpenSansnormal400 from './fonts/OpenSans/OpenSans-normal-400.ttf?url';
import OpenSansUrlOpenSansnormal500 from './fonts/OpenSans/OpenSans-normal-500.ttf?url';
import OpenSansUrlOpenSansnormal600 from './fonts/OpenSans/OpenSans-normal-600.ttf?url';
import OpenSansUrlOpenSansnormal700 from './fonts/OpenSans/OpenSans-normal-700.ttf?url';
import OpenSansUrlOpenSansnormal800 from './fonts/OpenSans/OpenSans-normal-800.ttf?url';

type FontWeight = 'normal' | 'bold' | 'light' | undefined;
type FontStyle = 'normal' | 'italic' | 'oblique' | undefined;

Font.register({
  family: 'OpenSans',
  fonts: [
    {
      src: OpenSansUrlOpenSansitalic300,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '300' as FontWeight,
    },
    {
      src: OpenSansUrlOpenSansitalic400,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '400' as FontWeight,
    },
    {
      src: OpenSansUrlOpenSansitalic500,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '500' as FontWeight,
    },
    {
      src: OpenSansUrlOpenSansitalic600,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '600' as FontWeight,
    },
    {
      src: OpenSansUrlOpenSansitalic700,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '700' as FontWeight,
    },
    {
      src: OpenSansUrlOpenSansitalic800,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '800' as FontWeight,
    },
    {
      src: OpenSansUrlOpenSansnormal300,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '300' as FontWeight,
    },
    {
      src: OpenSansUrlOpenSansnormal400,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '400' as FontWeight,
    },
    {
      src: OpenSansUrlOpenSansnormal500,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '500' as FontWeight,
    },
    {
      src: OpenSansUrlOpenSansnormal600,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '600' as FontWeight,
    },
    {
      src: OpenSansUrlOpenSansnormal700,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '700' as FontWeight,
    },
    {
      src: OpenSansUrlOpenSansnormal800,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '800' as FontWeight,
    },
  ],
});
