import { Font } from '@react-pdf/renderer';
import OswaldUrlOswaldnormal200 from './fonts/Oswald/Oswald-normal-200.ttf?url';
import OswaldUrlOswaldnormal300 from './fonts/Oswald/Oswald-normal-300.ttf?url';
import OswaldUrlOswaldnormal400 from './fonts/Oswald/Oswald-normal-400.ttf?url';
import OswaldUrlOswaldnormal500 from './fonts/Oswald/Oswald-normal-500.ttf?url';
import OswaldUrlOswaldnormal600 from './fonts/Oswald/Oswald-normal-600.ttf?url';
import OswaldUrlOswaldnormal700 from './fonts/Oswald/Oswald-normal-700.ttf?url';

type FontWeight = 'normal' | 'bold' | 'light' | undefined;
type FontStyle = 'normal' | 'italic' | 'oblique' | undefined;

Font.register({
  family: 'Oswald',
  fonts: [
    {
      src: OswaldUrlOswaldnormal200,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '200' as FontWeight,
    },
    {
      src: OswaldUrlOswaldnormal300,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '300' as FontWeight,
    },
    {
      src: OswaldUrlOswaldnormal400,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '400' as FontWeight,
    },
    {
      src: OswaldUrlOswaldnormal500,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '500' as FontWeight,
    },
    {
      src: OswaldUrlOswaldnormal600,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '600' as FontWeight,
    },
    {
      src: OswaldUrlOswaldnormal700,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '700' as FontWeight,
    },
  ],
});
