import { Container, Stack } from '@mantine/core';
import React from 'react';
import { IResumeWithSuggestion } from '../../models/Resume';
import { IUser } from '../../models/User';
import { Achievements } from './Achievements';
import { ContactInfo } from './ContactInfo';
import { Education } from './Education';
import { Experience } from './Experience';
import { Introduction } from './Introduction';
import { Skills } from './Skills';

export const ResumeView: React.FC<{
  resume: IResumeWithSuggestion;
  user: IUser;
  hidden: boolean;
}> = ({ resume, user, hidden }) => {
  const { suggestion } = resume;

  return (
    <Container size="md">
      <Stack gap="xl">
        <ContactInfo user={user} />
        {suggestion.intro && <Introduction resume={resume} hidden={hidden} />}
        {suggestion.experiences && (
          <Experience resume={resume} hidden={hidden} />
        )}
        {suggestion.educations && <Education resume={resume} hidden={hidden} />}
        {suggestion.skills && <Skills resume={resume} hidden={hidden} />}
        {suggestion.achievements && (
          <Achievements resume={resume} hidden={hidden} />
        )}
      </Stack>
    </Container>
  );
};
