import { Button, Stack, Text } from '@mantine/core';
import React from 'react';

interface FontPreviewButtonProps {
  fontCombination: {
    headerFont: string;
    headerFontUI: string;
    textFont: string;
    textFontUI: string;
  };
  onSelect: () => void;
  selected: boolean;
}

const FontPreviewButton: React.FC<FontPreviewButtonProps> = ({
  fontCombination,
  onSelect,
  selected,
}) => {
  return (
    <Button
      variant={selected ? 'filled' : 'outline'}
      onClick={onSelect}
      style={{
        margin: '0px',
        padding: '10px',
        minWidth: '128px',
        textAlign: 'left',
        whiteSpace: 'normal', // Allow text to wrap within the button
        height: '100px',
      }}
    >
      <Stack gap={0} align="center">
        <Text
          style={{
            fontFamily: fontCombination.headerFontUI,
            fontSize: '14px',
            marginBottom: '5px',
            display: 'block',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          <strong>{fontCombination.headerFont.split(' ')[0]}</strong>
        </Text>
        <Text
          style={{
            fontFamily: fontCombination.textFontUI,
            fontSize: '12px',
            display: 'block',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {fontCombination.textFont.split(' ')[0]}
        </Text>
      </Stack>
    </Button>
  );
};

export default FontPreviewButton;
