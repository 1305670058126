import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PDFProps, TemplateType } from '../PDFProps';
import {
  BulletPoints,
  ContactSection,
  createStyles,
  DEFAULT_HEADER_FONT,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_TEXT_FONT,
  ResumePDF,
} from '../ResumePDF';
import previewBasicPng from './preview-basic.png';
import previewBasicWebP from './preview-basic.webp';

// Define specific styles for this template
const specificStyles = (
  primaryColor: string,
  headerFont: string,
  textFont: string,
) =>
  StyleSheet.create({
    sectionTitle: {
      fontSize: 14,
      color: primaryColor,
      marginBottom: 2,
      borderBottomWidth: 1,
      borderBottomColor: primaryColor,
      fontFamily: headerFont,
    },
    header: {
      fontSize: 28,
      textAlign: 'left',
      marginBottom: 0,
      fontFamily: headerFont,
      color: primaryColor,
      marginLeft: 10,
    },
    subHeader: {
      fontSize: 18,
      marginBottom: 16,
      fontFamily: headerFont,
      color: '#666',
      textAlign: 'left',
      marginLeft: 10,
    },
    text: {
      color: '#555',
      fontFamily: textFont,
      lineHeight: 1.2,
    },
    subSection: {
      marginBottom: 4,
      padding: '4 10',
      color: '#555',
    },
    companyTitle: {
      fontFamily: headerFont,
      fontSize: 12,
      marginBottom: 0,
    },
    companyLocation: {
      fontFamily: headerFont,
      fontSize: 10,
      marginBottom: 2,
    },
    companyDates: {
      fontFamily: headerFont,
      fontSize: 10,
      marginBottom: 4,
    },
  });

export const ResumeBasicPDF: React.FC<PDFProps> = ({
  resume,
  user,
  title,
  language,
  primaryColor = DEFAULT_PRIMARY_COLOR,
  pageSize = DEFAULT_PAGE_SIZE,
  headerFont = DEFAULT_HEADER_FONT,
  textFont = DEFAULT_TEXT_FONT,
}) => {
  const styles = useMemo(
    () => ({
      ...createStyles(primaryColor || '#2F7D7A', headerFont, textFont),
      ...specificStyles(primaryColor || '#2F7D7A', headerFont, textFont),
    }),
    [primaryColor, headerFont, textFont],
  );
  const { t } = useTranslation(undefined, { lng: language });

  const languageLevels = {
    0: t('skills.basic'),
    1: t('skills.advanced'),
    2: t('skills.professional'),
    3: t('skills.native'),
  } as Record<number, string>;

  return (
    <ResumePDF
      user={user}
      title={title}
      language={language}
      primaryColor={primaryColor}
      pageSize={pageSize}
      headerFont={headerFont}
      textFont={textFont}
      customStyles={styles}
    >
      <Text style={styles.header}>
        {user.profile?.firstName} {user.profile?.lastName}
      </Text>
      <Text style={styles.subHeader}>{title}</Text>

      <ContactSection styles={styles} user={user} />

      <View style={styles.section}>
        <Text style={styles.sectionTitle}>
          {t('resume.personalInformation')}
        </Text>
        <Text style={styles.subSection}>{resume.intro}</Text>
      </View>

      {Array.isArray(resume.experiences) && resume.experiences.length > 0 && (
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>
            {t('resume.experience.title')}
          </Text>
          {resume.experiences.map((job, index) => (
            <View key={index} style={styles.subSection} wrap={false}>
              <Text style={styles.companyTitle}>{job.title}</Text>
              <Text style={styles.companyLocation}>
                {job.company.name}{' '}
                {job.company.location && `(${job.company.location})`}
              </Text>
              <Text style={styles.companyDates}>
                {job.start} - {job.end || t('timeline.present')}
              </Text>
              <BulletPoints points={job.summary} styles={styles} />
            </View>
          ))}
        </View>
      )}

      {Array.isArray(resume.educations) && resume.educations.length > 0 && (
        <View style={styles.section} wrap={false}>
          <Text style={styles.sectionTitle}>{t('resume.education.title')}</Text>
          {resume.educations.map((edu, index) => (
            <View key={index} style={styles.subSection} wrap={false}>
              <Text style={styles.companyTitle}>
                {edu.fieldOfStudy}, {edu.degree}
              </Text>
              <Text style={styles.companyLocation}>{edu.schoolName}</Text>

              <Text style={styles.companyDates}>
                {edu.start} - {edu.end || t('timeline.present')}
              </Text>
              <BulletPoints points={edu.notes} styles={styles} />
            </View>
          ))}
        </View>
      )}

      <View style={styles.section} wrap={false}>
        <Text style={styles.sectionTitle}>{t('resume.skills.title')}</Text>
        <View style={styles.subSection}>
          <BulletPoints points={resume.skills} styles={styles} />
        </View>
      </View>

      {user.profile?.languages && user.profile.languages.length > 0 && (
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>{t('resume.languages')}</Text>
          <View style={styles.subSection}>
            <BulletPoints
              points={user.profile.languages.map(
                (lang) =>
                  `${lang.name} (${languageLevels[Number(lang.level)]})`,
              )}
              styles={styles}
            />
          </View>
        </View>
      )}
    </ResumePDF>
  );
};

export const resumeTemplateBasic: TemplateType = {
  label: 'Basic',
  component: ResumeBasicPDF,
  preview: previewBasicWebP,
  previewFallback: previewBasicPng,
};
