import { Font } from '@react-pdf/renderer';
import RalewayUrlRalewayitalic100 from './fonts/Raleway/Raleway-italic-100.ttf?url';
import RalewayUrlRalewayitalic200 from './fonts/Raleway/Raleway-italic-200.ttf?url';
import RalewayUrlRalewayitalic300 from './fonts/Raleway/Raleway-italic-300.ttf?url';
import RalewayUrlRalewayitalic400 from './fonts/Raleway/Raleway-italic-400.ttf?url';
import RalewayUrlRalewayitalic500 from './fonts/Raleway/Raleway-italic-500.ttf?url';
import RalewayUrlRalewayitalic600 from './fonts/Raleway/Raleway-italic-600.ttf?url';
import RalewayUrlRalewayitalic700 from './fonts/Raleway/Raleway-italic-700.ttf?url';
import RalewayUrlRalewayitalic800 from './fonts/Raleway/Raleway-italic-800.ttf?url';
import RalewayUrlRalewayitalic900 from './fonts/Raleway/Raleway-italic-900.ttf?url';
import RalewayUrlRalewaynormal100 from './fonts/Raleway/Raleway-normal-100.ttf?url';
import RalewayUrlRalewaynormal200 from './fonts/Raleway/Raleway-normal-200.ttf?url';
import RalewayUrlRalewaynormal300 from './fonts/Raleway/Raleway-normal-300.ttf?url';
import RalewayUrlRalewaynormal400 from './fonts/Raleway/Raleway-normal-400.ttf?url';
import RalewayUrlRalewaynormal500 from './fonts/Raleway/Raleway-normal-500.ttf?url';
import RalewayUrlRalewaynormal600 from './fonts/Raleway/Raleway-normal-600.ttf?url';
import RalewayUrlRalewaynormal700 from './fonts/Raleway/Raleway-normal-700.ttf?url';
import RalewayUrlRalewaynormal800 from './fonts/Raleway/Raleway-normal-800.ttf?url';
import RalewayUrlRalewaynormal900 from './fonts/Raleway/Raleway-normal-900.ttf?url';

type FontWeight = 'normal' | 'bold' | 'light' | undefined;
type FontStyle = 'normal' | 'italic' | 'oblique' | undefined;

Font.register({
  family: 'Raleway',
  fonts: [
    {
      src: RalewayUrlRalewayitalic100,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '100' as FontWeight,
    },
    {
      src: RalewayUrlRalewayitalic200,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '200' as FontWeight,
    },
    {
      src: RalewayUrlRalewayitalic300,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '300' as FontWeight,
    },
    {
      src: RalewayUrlRalewayitalic400,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '400' as FontWeight,
    },
    {
      src: RalewayUrlRalewayitalic500,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '500' as FontWeight,
    },
    {
      src: RalewayUrlRalewayitalic600,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '600' as FontWeight,
    },
    {
      src: RalewayUrlRalewayitalic700,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '700' as FontWeight,
    },
    {
      src: RalewayUrlRalewayitalic800,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '800' as FontWeight,
    },
    {
      src: RalewayUrlRalewayitalic900,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '900' as FontWeight,
    },
    {
      src: RalewayUrlRalewaynormal100,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '100' as FontWeight,
    },
    {
      src: RalewayUrlRalewaynormal200,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '200' as FontWeight,
    },
    {
      src: RalewayUrlRalewaynormal300,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '300' as FontWeight,
    },
    {
      src: RalewayUrlRalewaynormal400,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '400' as FontWeight,
    },
    {
      src: RalewayUrlRalewaynormal500,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '500' as FontWeight,
    },
    {
      src: RalewayUrlRalewaynormal600,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '600' as FontWeight,
    },
    {
      src: RalewayUrlRalewaynormal700,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '700' as FontWeight,
    },
    {
      src: RalewayUrlRalewaynormal800,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '800' as FontWeight,
    },
    {
      src: RalewayUrlRalewaynormal900,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '900' as FontWeight,
    },
  ],
});
