import { Font } from '@react-pdf/renderer';
import NunitoUrlNunitoitalic200 from './fonts/Nunito/Nunito-italic-200.ttf?url';
import NunitoUrlNunitoitalic300 from './fonts/Nunito/Nunito-italic-300.ttf?url';
import NunitoUrlNunitoitalic400 from './fonts/Nunito/Nunito-italic-400.ttf?url';
import NunitoUrlNunitoitalic500 from './fonts/Nunito/Nunito-italic-500.ttf?url';
import NunitoUrlNunitoitalic600 from './fonts/Nunito/Nunito-italic-600.ttf?url';
import NunitoUrlNunitoitalic700 from './fonts/Nunito/Nunito-italic-700.ttf?url';
import NunitoUrlNunitoitalic800 from './fonts/Nunito/Nunito-italic-800.ttf?url';
import NunitoUrlNunitoitalic900 from './fonts/Nunito/Nunito-italic-900.ttf?url';
import NunitoUrlNunitonormal200 from './fonts/Nunito/Nunito-normal-200.ttf?url';
import NunitoUrlNunitonormal300 from './fonts/Nunito/Nunito-normal-300.ttf?url';
import NunitoUrlNunitonormal400 from './fonts/Nunito/Nunito-normal-400.ttf?url';
import NunitoUrlNunitonormal500 from './fonts/Nunito/Nunito-normal-500.ttf?url';
import NunitoUrlNunitonormal600 from './fonts/Nunito/Nunito-normal-600.ttf?url';
import NunitoUrlNunitonormal700 from './fonts/Nunito/Nunito-normal-700.ttf?url';
import NunitoUrlNunitonormal800 from './fonts/Nunito/Nunito-normal-800.ttf?url';
import NunitoUrlNunitonormal900 from './fonts/Nunito/Nunito-normal-900.ttf?url';

type FontWeight = 'normal' | 'bold' | 'light' | undefined;
type FontStyle = 'normal' | 'italic' | 'oblique' | undefined;

Font.register({
  family: 'Nunito',
  fonts: [
    {
      src: NunitoUrlNunitoitalic200,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '200' as FontWeight,
    },
    {
      src: NunitoUrlNunitoitalic300,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '300' as FontWeight,
    },
    {
      src: NunitoUrlNunitoitalic400,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '400' as FontWeight,
    },
    {
      src: NunitoUrlNunitoitalic500,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '500' as FontWeight,
    },
    {
      src: NunitoUrlNunitoitalic600,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '600' as FontWeight,
    },
    {
      src: NunitoUrlNunitoitalic700,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '700' as FontWeight,
    },
    {
      src: NunitoUrlNunitoitalic800,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '800' as FontWeight,
    },
    {
      src: NunitoUrlNunitoitalic900,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '900' as FontWeight,
    },
    {
      src: NunitoUrlNunitonormal200,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '200' as FontWeight,
    },
    {
      src: NunitoUrlNunitonormal300,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '300' as FontWeight,
    },
    {
      src: NunitoUrlNunitonormal400,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '400' as FontWeight,
    },
    {
      src: NunitoUrlNunitonormal500,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '500' as FontWeight,
    },
    {
      src: NunitoUrlNunitonormal600,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '600' as FontWeight,
    },
    {
      src: NunitoUrlNunitonormal700,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '700' as FontWeight,
    },
    {
      src: NunitoUrlNunitonormal800,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '800' as FontWeight,
    },
    {
      src: NunitoUrlNunitonormal900,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '900' as FontWeight,
    },
  ],
});
