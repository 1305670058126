import languages from '@cospired/i18n-iso-languages';
import de from '@cospired/i18n-iso-languages/langs/de.json';
import en from '@cospired/i18n-iso-languages/langs/en.json';
import es from '@cospired/i18n-iso-languages/langs/es.json';
import fr from '@cospired/i18n-iso-languages/langs/fr.json';
import it from '@cospired/i18n-iso-languages/langs/it.json';
import pt from '@cospired/i18n-iso-languages/langs/pt.json';

import dayjs from 'dayjs';
import './util/dayjs-i18n';

import i18n, { InitOptions } from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

import { IS_DEVELOPMENT, UI_LANGUAGES } from './Config';

export const defaultNS = 'translation';

export const uiLanguages = UI_LANGUAGES;

languages.registerLocale(en);
languages.registerLocale(de);
languages.registerLocale(es);
languages.registerLocale(fr);
languages.registerLocale(it);
languages.registerLocale(pt);

i18n
  .use(I18nextBrowserLanguageDetector)
  .use(
    resourcesToBackend(
      (language: string) =>
        import(
          /* webpackChunkName: "[request]" */ `./locales/${language}.json`
        ),
    ),
  )
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    caches: ['localStorage'],
    supportedLngs: uiLanguages,
    debug: IS_DEVELOPMENT,
    defaultNS,
    fallbackLng: 'en',
    load: 'languageOnly',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  } as InitOptions<I18nextBrowserLanguageDetector>)
  .then(() => dayjs.locale(i18n.languages[0]))
  .catch((error) => {
    console.error('Could not initialize i18n, error:', error);
  });

export const changeLanguage = async (language: string) => {
  localStorage.setItem('i18nextLng', language);
  await i18n.changeLanguage(language);
  dayjs.locale(language);
};

export default i18n;
