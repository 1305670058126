import {
  ActionIcon,
  Button,
  Group,
  Modal,
  Stack,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconCheck, IconPlus, IconTrash, IconX } from '@tabler/icons-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IResumeJSONEducation } from '../../models/Resume';

export const EducationModal: React.FC<{
  opened: boolean;
  onClose: () => void;
  onSave: (values: IResumeJSONEducation) => void;
  initialValues: IResumeJSONEducation;
}> = ({ opened, onClose, onSave, initialValues }) => {
  const { t } = useTranslation();

  const form = useForm({
    initialValues,
    validate: {
      degree: (value) => (!value ? t('resume.education.degreeRequired') : null),
      fieldOfStudy: (value) =>
        !value ? t('resume.education.fieldOfStudyRequired') : null,
      schoolName: (value) =>
        !value ? t('resume.education.schoolNameRequired') : null,
      start: (value) =>
        !value ? t('resume.education.startDateRequired') : null,
      notes: (value) =>
        value.length === 0 || value.some((item) => !item)
          ? t('resume.education.notesRequired')
          : null,
    },
  });

  useEffect(() => {
    form.setValues(initialValues);
  }, [initialValues]);

  const handleSave = () => {
    if (form.isValid()) {
      onSave(form.values);
      onClose();
    }
  };

  const handleNotesChange = (index: number, value: string) => {
    const newNotes = [...form.values.notes];
    newNotes[index] = value;
    form.setFieldValue('notes', newNotes);
    form.validateField('notes');
  };

  const addNote = () => {
    form.setFieldValue('notes', [...form.values.notes, '']);
  };

  const removeNote = (index: number) => {
    if (form.values.notes.length > 1) {
      const newNotes = form.values.notes.filter((_, idx) => idx !== index);
      form.setFieldValue('notes', newNotes);
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={t('resume.education.editEducation')}
      centered
      size="xl"
    >
      <form onSubmit={form.onSubmit(handleSave)}>
        <Stack gap="md">
          <TextInput
            label={t('resume.education.degree')}
            {...form.getInputProps('degree')}
            onBlur={() => form.validateField('degree')}
          />
          <TextInput
            label={t('resume.education.fieldOfStudy')}
            {...form.getInputProps('fieldOfStudy')}
            onBlur={() => form.validateField('fieldOfStudy')}
          />
          <TextInput
            label={t('resume.education.schoolName')}
            {...form.getInputProps('schoolName')}
            onBlur={() => form.validateField('schoolName')}
          />
          <TextInput
            label={t('resume.education.startDate')}
            {...form.getInputProps('start')}
            onBlur={() => form.validateField('start')}
          />
          <TextInput
            label={t('resume.education.endDate')}
            {...form.getInputProps('end')}
            onBlur={() => form.validateField('end')}
          />
          <div>
            <Title order={5} mt="md">
              {t('resume.education.notes')}
            </Title>
            {form.values.notes.map((note, index) => (
              <Group key={index} align="flex-start" mt="xs">
                <Textarea
                  value={note}
                  onChange={(event) => {
                    handleNotesChange(index, event.currentTarget.value);
                  }}
                  autosize
                  minRows={1}
                  style={{ flex: 1 }}
                />
                {form.values.notes.length > 1 && (
                  <ActionIcon
                    color="red"
                    onClick={() => {
                      removeNote(index);
                    }}
                  >
                    <IconTrash />
                  </ActionIcon>
                )}
              </Group>
            ))}
            <Button mt="md" leftSection={<IconPlus />} onClick={addNote}>
              {t('resume.education.addNote')}
            </Button>
          </div>
        </Stack>
        <Group justify="flex-end" mt="md" gap="sm">
          <Button variant="outline" onClick={onClose}>
            <IconX />
            {t('labels.cancel')}
          </Button>
          <Button type="submit" disabled={!form.isValid()}>
            <IconCheck />
            {t('labels.save')}
          </Button>
        </Group>
      </form>
    </Modal>
  );
};
