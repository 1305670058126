import { Font } from '@react-pdf/renderer';
import LatoUrlLatoitalic100 from './fonts/Lato/Lato-italic-100.ttf?url';
import LatoUrlLatoitalic300 from './fonts/Lato/Lato-italic-300.ttf?url';
import LatoUrlLatoitalic400 from './fonts/Lato/Lato-italic-400.ttf?url';
import LatoUrlLatoitalic700 from './fonts/Lato/Lato-italic-700.ttf?url';
import LatoUrlLatoitalic900 from './fonts/Lato/Lato-italic-900.ttf?url';
import LatoUrlLatonormal100 from './fonts/Lato/Lato-normal-100.ttf?url';
import LatoUrlLatonormal300 from './fonts/Lato/Lato-normal-300.ttf?url';
import LatoUrlLatonormal400 from './fonts/Lato/Lato-normal-400.ttf?url';
import LatoUrlLatonormal700 from './fonts/Lato/Lato-normal-700.ttf?url';
import LatoUrlLatonormal900 from './fonts/Lato/Lato-normal-900.ttf?url';

type FontWeight = 'normal' | 'bold' | 'light' | undefined;
type FontStyle = 'normal' | 'italic' | 'oblique' | undefined;

Font.register({
  family: 'Lato',
  fonts: [
    {
      src: LatoUrlLatoitalic100,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '100' as FontWeight,
    },
    {
      src: LatoUrlLatoitalic400,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '400' as FontWeight,
    },
    {
      src: LatoUrlLatoitalic300,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '300' as FontWeight,
    },
    {
      src: LatoUrlLatoitalic700,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '700' as FontWeight,
    },
    {
      src: LatoUrlLatoitalic900,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '900' as FontWeight,
    },
    {
      src: LatoUrlLatonormal100,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '100' as FontWeight,
    },
    {
      src: LatoUrlLatonormal300,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '300' as FontWeight,
    },
    {
      src: LatoUrlLatonormal400,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '400' as FontWeight,
    },
    {
      src: LatoUrlLatonormal900,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '900' as FontWeight,
    },
    {
      src: LatoUrlLatonormal700,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '700' as FontWeight,
    },
  ],
});
