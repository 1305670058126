import { Font } from '@react-pdf/renderer';
import RobotoUrlRobotoitalic100 from './fonts/Roboto/Roboto-italic-100.ttf?url';
import RobotoUrlRobotoitalic300 from './fonts/Roboto/Roboto-italic-300.ttf?url';
import RobotoUrlRobotoitalic400 from './fonts/Roboto/Roboto-italic-400.ttf?url';
import RobotoUrlRobotoitalic500 from './fonts/Roboto/Roboto-italic-500.ttf?url';
import RobotoUrlRobotoitalic700 from './fonts/Roboto/Roboto-italic-700.ttf?url';
import RobotoUrlRobotoitalic900 from './fonts/Roboto/Roboto-italic-900.ttf?url';
import RobotoUrlRobotonormal100 from './fonts/Roboto/Roboto-normal-100.ttf?url';
import RobotoUrlRobotonormal300 from './fonts/Roboto/Roboto-normal-300.ttf?url';
import RobotoUrlRobotonormal400 from './fonts/Roboto/Roboto-normal-400.ttf?url';
import RobotoUrlRobotonormal500 from './fonts/Roboto/Roboto-normal-500.ttf?url';
import RobotoUrlRobotonormal700 from './fonts/Roboto/Roboto-normal-700.ttf?url';
import RobotoUrlRobotonormal900 from './fonts/Roboto/Roboto-normal-900.ttf?url';

type FontWeight = 'normal' | 'bold' | 'light' | undefined;
type FontStyle = 'normal' | 'italic' | 'oblique' | undefined;

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RobotoUrlRobotoitalic100,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '100' as FontWeight,
    },
    {
      src: RobotoUrlRobotoitalic300,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '300' as FontWeight,
    },
    {
      src: RobotoUrlRobotoitalic400,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '400' as FontWeight,
    },
    {
      src: RobotoUrlRobotoitalic500,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '500' as FontWeight,
    },
    {
      src: RobotoUrlRobotoitalic700,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '700' as FontWeight,
    },
    {
      src: RobotoUrlRobotoitalic900,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '900' as FontWeight,
    },
    {
      src: RobotoUrlRobotonormal100,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '100' as FontWeight,
    },
    {
      src: RobotoUrlRobotonormal300,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '300' as FontWeight,
    },
    {
      src: RobotoUrlRobotonormal400,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '400' as FontWeight,
    },
    {
      src: RobotoUrlRobotonormal500,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '500' as FontWeight,
    },
    {
      src: RobotoUrlRobotonormal700,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '700' as FontWeight,
    },
    {
      src: RobotoUrlRobotonormal900,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '900' as FontWeight,
    },
  ],
});
