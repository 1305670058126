import { Styles, StyleSheet } from '@react-pdf/renderer';
import { deepmerge } from 'deepmerge-ts';

export function createCoverLetterStyles<T>(
  primaryColor: string,
  headerFont: string,
  textFont: string,
  specificStyles?: (
    primaryColor: string,
    headerFont: string,
    textFont: string,
  ) => T,
) {
  return StyleSheet.create(
    deepmerge(
      {
        page: {
          padding: 72,
          fontSize: 12,
          fontFamily: textFont,
          backgroundColor: '#FAFAFA',
        },
        header: {
          fontSize: 28,
          textAlign: 'center',
          marginBottom: 20,
          fontFamily: headerFont,
          fontWeight: 'bold',
          color: primaryColor,
        },
        subHeader: {
          fontSize: 20,
          marginBottom: 10,
          fontFamily: headerFont,
          fontWeight: 400,
          color: '#555',
          textAlign: 'center',
        },
        text: {
          color: '#333',
          fontSize: 10,
          fontFamily: textFont,
        },
        content: {
          fontSize: 10,
          color: '#333',
          fontFamily: textFont,
          lineHeight: 1.5,
        },
        contentSection: {
          marginBottom: 12,
        },
        dateSection: {
          marginBottom: 32,
          alignItems: 'flex-end',
        },
      },
      specificStyles?.(primaryColor, headerFont, textFont),
    ) as Styles,
  ) as T & {
    page: Styles;
    header: Styles;
    subHeader: Styles;
    text: Styles;
    content: Styles;
    contentSection: Styles;
    dateSection: Styles;
  };
}
