import {
  Box,
  Button,
  FocusTrap,
  Group,
  Modal,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { IResume } from '../../models/Resume';
import { useUpdateResumeMutation } from '../../queries/ResumeQueries';

const TitleSchema = Yup.object().shape({
  role: Yup.string().required(),
  company: Yup.string().required(),
});

export const TitleModal: React.FC<{
  opened: boolean;
  onClose: () => void;
  resume: IResume;
}> = ({ opened, onClose, resume }) => {
  const { t } = useTranslation();

  const updateResumeMutation = useUpdateResumeMutation(resume.id);

  const titleParts = resume.title?.split(', ') || [];
  const initialValues = {
    role: titleParts[0] || '',
    company: titleParts.length > 1 ? titleParts[1] : '',
  };

  const handleClose = () => {
    onClose();
  };

  const onSubmit = (
    values: typeof initialValues,
    actions: FormikHelpers<typeof initialValues>,
  ) =>
    updateResumeMutation
      .mutateAsync({
        title: values.role + ', ' + values.company,
      })
      .then(() => {
        actions.resetForm({ values });
        onClose();
      })
      .catch((error) => {
        actions.setStatus(error);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      centered
      size="xl"
      title={
        <Text fz="lg" fw="bolder">
          {t('resume.editTitleTitle')}
        </Text>
      }
    >
      <Box p={{ base: 0, md: 'md' }}>
        <FocusTrap.InitialFocus />
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={TitleSchema}
        >
          {(formikProps) => (
            <Form onSubmit={formikProps.handleSubmit}>
              <Stack gap="md">
                <Group align="flex-start" mt="xs">
                  <TextInput
                    {...formikProps.getFieldProps('role')}
                    label={t('resume.experience.jobTitle')}
                    style={{ flex: 1 }}
                  />
                  <TextInput
                    {...formikProps.getFieldProps('company')}
                    label={t('resume.experience.companyName')}
                    style={{ flex: 1 }}
                  />
                </Group>
              </Stack>
              <Group justify="flex-end" mt="md" gap="sm">
                <Button variant="outline" onClick={handleClose}>
                  {t('labels.cancel')}
                </Button>
                <Button
                  type="submit"
                  disabled={
                    !formikProps.isValid ||
                    !formikProps.dirty ||
                    formikProps.isSubmitting
                  }
                  loading={formikProps.isSubmitting}
                >
                  {t('labels.save')}
                </Button>
              </Group>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};
