import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { PRODUCT_NAME } from '../../Constants';
import { uiLanguages } from '../../i18n';

interface BowlProps extends React.ComponentProps<typeof Helmet> {
  enableHrefLang?: boolean;
  language?: string;
}

export const Bowl: React.FC<BowlProps> = ({
  children,
  enableHrefLang = true,
  language,
  ...props
}) => {
  useLocation();

  const { i18n } = useTranslation();

  const url = new URL(window.location.href);
  const searchParams = url.searchParams;

  const currentLanguage = i18n.languages[0].substring(0, 2);

  const languages = [...uiLanguages, 'x-default'];

  const hrefLangs = languages.map((lng) => {
    if (lng === 'x-default') {
      searchParams.delete('lng');
    } else {
      searchParams.set('lng', lng);
    }
    return (
      <link rel="alternate" hrefLang={lng} href={url.toString()} key={lng} />
    );
  });

  searchParams.delete('lng');
  const canonical = <link rel="canonical" href={url.toString()} />;

  return (
    <Helmet
      titleTemplate={`%s · ${PRODUCT_NAME}`}
      defaultTitle={PRODUCT_NAME}
      {...props}
    >
      <html lang={language || currentLanguage} />

      <meta property="og:url" content={url.toString()} />

      {enableHrefLang ? hrefLangs : canonical}

      {children}
    </Helmet>
  );
};
