import { Paper, SimpleGrid, Stack, Title } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FontPreviewButton from './FontPreviewButton';

// Supports weights 400-900
import '@fontsource-variable/playfair-display';
import '@fontsource/lato';

// Supports weights 100-900
import '@fontsource-variable/montserrat';
// Supports weights 300-800
import '@fontsource-variable/open-sans';

// Supports weights 200-900
import '@fontsource-variable/nunito';
import '@fontsource/roboto';

// Supports weights 200-700
import '@fontsource-variable/oswald';
// Supports weights 100-900
import '@fontsource-variable/raleway';

import '@fontsource/merriweather';
import '@fontsource/pt-sans';

const FONT_COMBINATIONS = [
  {
    headerFont: 'Nunito',
    headerFontUI: 'Nunito Variable',
    textFont: 'Roboto',
    textFontUI: 'Roboto',
  },
  {
    headerFont: 'PlayfairDisplay',
    headerFontUI: 'Playfair Display Variable',
    textFont: 'Lato',
    textFontUI: 'Lato',
  },
  {
    headerFont: 'Montserrat',
    headerFontUI: 'Montserrat Variable',
    textFont: 'OpenSans',
    textFontUI: 'Open Sans Variable',
  },
  {
    headerFont: 'Oswald',
    headerFontUI: 'Oswald Variable',
    textFont: 'Raleway',
    textFontUI: 'Raleway Variable',
  },
  {
    headerFont: 'Merriweather',
    headerFontUI: 'Merriweather',
    textFont: 'PTSans',
    textFontUI: 'PT Sans',
  },
] as const;

interface FontSelectorProps {
  onFontChange: (headerFont: string, textFont: string, index: number) => void;
  selectedCombinationIndex: number;
}

const FontSelector: React.FC<FontSelectorProps> = ({
  onFontChange,
  selectedCombinationIndex,
}) => {
  const { t } = useTranslation();

  const [selectedCombination, setSelectedCombination] = useState(
    selectedCombinationIndex,
  );

  useEffect(() => {
    setSelectedCombination(selectedCombinationIndex);
  }, [selectedCombinationIndex]);

  const handleSelect = (index: number) => {
    setSelectedCombination(index);
    const { headerFont, textFont } = FONT_COMBINATIONS[index];
    onFontChange(headerFont, textFont, index);
  };

  return (
    <Paper withBorder p="sm">
      <Stack gap={4}>
        <Title order={6} c="dimmed">
          {t('view.pdf.fontsTitle')}
        </Title>
        <SimpleGrid cols={{ base: 2, sm: 2, md: 2, lg: 3 }}>
          {FONT_COMBINATIONS.map((combo, index) => (
            <FontPreviewButton
              key={index}
              fontCombination={combo}
              onSelect={() => {
                handleSelect(index);
              }}
              selected={index === selectedCombination}
            />
          ))}
        </SimpleGrid>
      </Stack>
    </Paper>
  );
};

export default FontSelector;
