import { Center, Container, Loader, Stack } from '@mantine/core';
import React from 'react';
import { Logo } from '../Logo/Logo';

export const LoadingScreen: React.FC = () => (
  <Container fluid h="90vh" w="100vw">
    <Center w="100%" h="100%">
      <Stack align="center">
        <Logo type="Banner" w="400px" h="auto" />
        <Loader size="xl" type="dots" />
      </Stack>
    </Center>
  </Container>
);
