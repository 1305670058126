import { Font } from '@react-pdf/renderer';
import MontserratUrlMontserratitalic100 from './fonts/Montserrat/Montserrat-italic-100.ttf?url';
import MontserratUrlMontserratitalic200 from './fonts/Montserrat/Montserrat-italic-200.ttf?url';
import MontserratUrlMontserratitalic300 from './fonts/Montserrat/Montserrat-italic-300.ttf?url';
import MontserratUrlMontserratitalic400 from './fonts/Montserrat/Montserrat-italic-400.ttf?url';
import MontserratUrlMontserratitalic500 from './fonts/Montserrat/Montserrat-italic-500.ttf?url';
import MontserratUrlMontserratitalic600 from './fonts/Montserrat/Montserrat-italic-600.ttf?url';
import MontserratUrlMontserratitalic700 from './fonts/Montserrat/Montserrat-italic-700.ttf?url';
import MontserratUrlMontserratitalic800 from './fonts/Montserrat/Montserrat-italic-800.ttf?url';
import MontserratUrlMontserratitalic900 from './fonts/Montserrat/Montserrat-italic-900.ttf?url';
import MontserratUrlMontserratnormal100 from './fonts/Montserrat/Montserrat-normal-100.ttf?url';
import MontserratUrlMontserratnormal200 from './fonts/Montserrat/Montserrat-normal-200.ttf?url';
import MontserratUrlMontserratnormal300 from './fonts/Montserrat/Montserrat-normal-300.ttf?url';
import MontserratUrlMontserratnormal400 from './fonts/Montserrat/Montserrat-normal-400.ttf?url';
import MontserratUrlMontserratnormal500 from './fonts/Montserrat/Montserrat-normal-500.ttf?url';
import MontserratUrlMontserratnormal600 from './fonts/Montserrat/Montserrat-normal-600.ttf?url';
import MontserratUrlMontserratnormal700 from './fonts/Montserrat/Montserrat-normal-700.ttf?url';
import MontserratUrlMontserratnormal800 from './fonts/Montserrat/Montserrat-normal-800.ttf?url';
import MontserratUrlMontserratnormal900 from './fonts/Montserrat/Montserrat-normal-900.ttf?url';

type FontWeight = 'normal' | 'bold' | 'light' | undefined;
type FontStyle = 'normal' | 'italic' | 'oblique' | undefined;

Font.register({
  family: 'Montserrat',
  fonts: [
    {
      src: MontserratUrlMontserratitalic100,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '100' as FontWeight,
    },
    {
      src: MontserratUrlMontserratitalic200,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '200' as FontWeight,
    },
    {
      src: MontserratUrlMontserratitalic300,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '300' as FontWeight,
    },
    {
      src: MontserratUrlMontserratitalic400,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '400' as FontWeight,
    },
    {
      src: MontserratUrlMontserratitalic500,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '500' as FontWeight,
    },
    {
      src: MontserratUrlMontserratitalic600,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '600' as FontWeight,
    },
    {
      src: MontserratUrlMontserratitalic700,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '700' as FontWeight,
    },
    {
      src: MontserratUrlMontserratitalic800,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '800' as FontWeight,
    },
    {
      src: MontserratUrlMontserratitalic900,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '900' as FontWeight,
    },
    {
      src: MontserratUrlMontserratnormal100,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '100' as FontWeight,
    },
    {
      src: MontserratUrlMontserratnormal200,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '200' as FontWeight,
    },
    {
      src: MontserratUrlMontserratnormal300,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '300' as FontWeight,
    },
    {
      src: MontserratUrlMontserratnormal400,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '400' as FontWeight,
    },
    {
      src: MontserratUrlMontserratnormal500,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '500' as FontWeight,
    },
    {
      src: MontserratUrlMontserratnormal600,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '600' as FontWeight,
    },
    {
      src: MontserratUrlMontserratnormal700,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '700' as FontWeight,
    },
    {
      src: MontserratUrlMontserratnormal800,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '800' as FontWeight,
    },
    {
      src: MontserratUrlMontserratnormal900,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '900' as FontWeight,
    },
  ],
});
