import {
  Accordion,
  ActionIcon,
  Box,
  CopyButton,
  Group,
  Paper,
  Text,
  Tooltip,
} from '@mantine/core';
import { IconCopy } from '@tabler/icons-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const TextAccordionPanel: React.FC<{ text?: string; max: number }> = ({
  text,
  max,
}) => {
  const { t } = useTranslation();
  return (
    <Accordion.Panel>
      <Paper p="lg" withBorder shadow="md">
        <CopyButton value={text || ''}>
          {({ copied, copy }) => (
            <Box pos="relative">
              <Tooltip
                label={copied ? t('labels.copied') : t('labels.copy')}
                opened={copied || undefined}
              >
                <ActionIcon
                  color={copied ? 'primary' : 'gray.6'}
                  pos="absolute"
                  right={0}
                  variant="light"
                  size="sm"
                  onClick={copy}
                >
                  <IconCopy />
                </ActionIcon>
              </Tooltip>
            </Box>
          )}
        </CopyButton>
        <Text size="sm" style={{ whiteSpace: 'pre-wrap' }}>
          {text || '-'}
        </Text>
        <Group justify="flex-end">
          <Text size="xs" fw={200} mt="md">
            {text?.length || 0}/{max} {t('view.characters')}
          </Text>
        </Group>
      </Paper>
    </Accordion.Panel>
  );
};
