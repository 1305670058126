import { Font } from '@react-pdf/renderer';
import PTSansUrlPTSansitalic400 from './fonts/PTSans/PTSans-italic-400.ttf?url';
import PTSansUrlPTSansitalic700 from './fonts/PTSans/PTSans-italic-700.ttf?url';
import PTSansUrlPTSansnormal400 from './fonts/PTSans/PTSans-normal-400.ttf?url';
import PTSansUrlPTSansnormal700 from './fonts/PTSans/PTSans-normal-700.ttf?url';

type FontWeight = 'normal' | 'bold' | 'light' | undefined;
type FontStyle = 'normal' | 'italic' | 'oblique' | undefined;

Font.register({
  family: 'PTSans',
  fonts: [
    {
      src: PTSansUrlPTSansitalic400,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '400' as FontWeight,
    },
    {
      src: PTSansUrlPTSansitalic700,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '700' as FontWeight,
    },
    {
      src: PTSansUrlPTSansnormal400,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '400' as FontWeight,
    },
    {
      src: PTSansUrlPTSansnormal700,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '700' as FontWeight,
    },
  ],
});
