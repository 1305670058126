interface ResumePDFViewerProps
  extends React.IframeHTMLAttributes<HTMLIFrameElement> {
  innerRef?: React.RefObject<HTMLIFrameElement>;
  showToolbar?: boolean;
  url: string | null;
}

export const ResumePDFViewer: React.FC<ResumePDFViewerProps> = ({
  innerRef,
  url,
  showToolbar = true,
  ...props
}: ResumePDFViewerProps) => {
  const src = url ? `${url}#toolbar=${showToolbar ? 1 : 0}` : null;

  return src && <iframe src={src} ref={innerRef} {...props} />;
};
