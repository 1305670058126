import {
  Document,
  Page,
  PageProps,
  Styles,
  StyleSheet,
} from '@react-pdf/renderer';
import dayjs from 'dayjs';
import React, { PropsWithChildren, ReactNode, useMemo } from 'react';
import { IUser } from '../../models/User';
import { getLogger } from '../../services/Logger';

import '../registerFonts';

export const DEFAULT_PRIMARY_COLOR = '#2F7D7A';
export const DEFAULT_HEADER_FONT = 'Nunito';
export const DEFAULT_TEXT_FONT = 'Roboto';
export const DEFAULT_PAGE_SIZE = 'A4';

const log = getLogger('CoverLetterPDF');

export function currentDateString(language: string) {
  if (!dayjs().locale(language).format) {
    log.warn('no locale found for', language);
  }
  return dayjs().locale(language).format
    ? dayjs().locale(language).format('L')
    : dayjs().format('L');
}

export const createCoverLetterStyles = (
  primaryColor: string,
  headerFont: string,
  textFont: string,
  specificStyles?: Styles,
) =>
  StyleSheet.create({
    page: {
      padding: 72,
      fontSize: 12,
      fontFamily: textFont,
      backgroundColor: '#FAFAFA',
    },
    header: {
      fontSize: 28,
      textAlign: 'center',
      marginBottom: 20,
      fontFamily: headerFont,
      fontWeight: 'bold',
      color: primaryColor,
    },
    subHeader: {
      fontSize: 20,
      marginBottom: 10,
      fontFamily: headerFont,
      fontWeight: 400,
      color: '#555',
      textAlign: 'center',
    },
    text: {
      color: '#333',
      fontSize: 10,
      fontFamily: textFont,
    },
    content: {
      fontSize: 10,
      color: '#333',
      fontFamily: textFont,
      lineHeight: 1.5,
    },
    contentSection: {
      marginBottom: 12,
    },
    ...specificStyles,
  });

export const CoverLetterPDF: React.FC<{
  children: ReactNode;
  user: IUser;
  language: string;
  title?: string;
  primaryColor?: string;
  pageSize?: string;
  headerFont?: string;
  textFont?: string;
  customStyles?: Styles;
}> = ({
  children,
  user,
  language,
  title,
  primaryColor = DEFAULT_PRIMARY_COLOR,
  pageSize = DEFAULT_PAGE_SIZE,
  headerFont = DEFAULT_HEADER_FONT,
  textFont = DEFAULT_TEXT_FONT,
  customStyles,
}) => {
  const base = useMemo(
    () => createCoverLetterStyles(primaryColor, headerFont, textFont),
    [primaryColor, headerFont, textFont],
  );
  const styles = customStyles ? customStyles : base;

  return (
    <Document
      author={`${user.profile?.firstName || ''} ${user.profile?.lastName || ''}`}
      producer="ResuFit"
      creator="ResuFit"
      title={title}
      creationDate={new Date()}
      language={language}
    >
      <Page
        size={pageSize as Readonly<PropsWithChildren<PageProps>>['size']}
        style={styles.page}
      >
        {children}
      </Page>
    </Document>
  );
};
