import { ActionIcon, Group, List, Stack, Title } from '@mantine/core';
import {
  IconCaretDown,
  IconCaretUp,
  IconPencil,
  IconTrash,
} from '@tabler/icons-react';
import React from 'react';
import { IResumeJSONEducation } from '../../models/Resume';
import { SubscribeCTAOverlay } from '../SubscribeCTAOverlay/SubscribeCTAOverlay';

export const EducationSection: React.FC<{
  section: IResumeJSONEducation;
  index: number;
  hidden: boolean;
  onEdit: () => void;
  onRemove: () => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
}> = ({ section, index, hidden, onEdit, onRemove, onMoveUp, onMoveDown }) => {
  return (
    <Stack style={{ userSelect: hidden ? 'none' : 'auto' }}>
      <Group justify="space-between" align="center" gap="xs" wrap="nowrap">
        <Title order={5} style={{ flex: 1 }}>
          {section.schoolName}
        </Title>

        <Group gap={4}>
          <ActionIcon
            variant="subtle"
            onClick={onEdit}
            disabled={hidden}
            size="sm"
          >
            <IconPencil />
          </ActionIcon>
          {onMoveUp && (
            <ActionIcon
              variant="subtle"
              onClick={onMoveUp}
              size="sm"
              disabled={hidden}
            >
              <IconCaretUp />
            </ActionIcon>
          )}
          {onMoveDown && (
            <ActionIcon
              variant="subtle"
              onClick={onMoveDown}
              size="sm"
              disabled={hidden}
            >
              <IconCaretDown />
            </ActionIcon>
          )}
          <ActionIcon
            variant="subtle"
            onClick={onRemove}
            size="sm"
            disabled={hidden}
          >
            <IconTrash />
          </ActionIcon>
        </Group>
      </Group>
      <div>
        <List size="md">
          {Array.isArray(section.notes)
            ? section.notes.map((note, idx) => (
                <List.Item key={idx}>{note}</List.Item>
              ))
            : section.notes || ''}
        </List>
        <SubscribeCTAOverlay
          enabled={hidden && index > -1}
          style={{
            marginLeft: '1em',
            marginRight: '-0.5em',
            marginTop: '2em',
          }}
        />
      </div>
    </Stack>
  );
};
