import { Badge, Group } from '@mantine/core';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IResume } from '../../models/Resume';

export const ResumeBadgesGroup: React.FC<{ resume: IResume }> = ({
  resume,
}) => {
  const { t } = useTranslation();
  return (
    <Group gap="sm">
      <Badge color="lime">
        {resume.style && (
          <span>
            {t('resume.style')}: {t(`styles.${resume.style}`)}
          </span>
        )}
      </Badge>
      <Badge color="grey">
        {resume.language && (
          <span>
            {t('labels.language')}: {t(`languages.${resume.language}`)}
          </span>
        )}
      </Badge>
      <Badge>
        {t('view.createdBadge')} {dayjs(resume.createdAt).fromNow()}
      </Badge>
      {resume.updatedAt && (
        <Badge color="teal">
          {t('view.updatedBadge')} {dayjs(resume.updatedAt).fromNow()}
        </Badge>
      )}
    </Group>
  );
};
