import { Link, StyleSheet, Text, View } from '@react-pdf/renderer';
import '../../util/dayjs-i18n';
import React, { useMemo } from 'react';

import {
  CoverLetterPDF,
  createCoverLetterStyles,
  currentDateString,
  DEFAULT_HEADER_FONT,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PRIMARY_COLOR,
  DEFAULT_TEXT_FONT,
} from '../CoverLetterPDF/CoverLetterPDF';
import { PDFProps, TemplateType } from '../PDFProps';

import previewPng from '../ResumeSideBarPDF/preview-sidebar.png';
import previewWebP from '../ResumeSideBarPDF/preview-sidebar.webp';

const specificCoverLetterStyles = (
  primaryColor: string,
  headerFont: string,
  textFont: string,
) =>
  StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#FAFAFA',
      padding: 0,
      fontSize: 12,
      fontFamily: textFont,
    },
    sidebar: {
      width: '30%',
      padding: '48 16 48 16',
      backgroundColor: primaryColor,
      color: '#FFFFFF',
    },
    sidebarHeader: {
      fontSize: 14,
      marginBottom: 10,
      fontFamily: headerFont,
    },
    sidebarText: {
      fontSize: 10,
      fontWeight: 'semibold',
      marginBottom: 5,
      fontFamily: textFont,
      color: '#EEE',
    },
    content: {
      width: '70%',
      padding: '0 24 0 24',
    },
    specificHeader: {
      fontSize: 20,
      fontFamily: headerFont,
      fontWeight: 'bold',
      color: '#333',
      marginBottom: 10,
    },
    subHeader: {
      fontSize: 12,
      fontFamily: headerFont,
      color: '#555',
      marginBottom: 32,
    },
    dateSection: {
      marginBottom: 32,
      alignItems: 'flex-end',
    },
    container: {
      flexDirection: 'row',
      width: '100%',
    },
    headerPadding: {
      height: 48,
    },
    linkStyle: {
      fontWeight: 'normal',
      textDecoration: 'none',
      marginBottom: 5,
      fontFamily: textFont,
      color: '#EEE',
      fontSize: 10,
    },
  });

export const CoverLetterSideBarPDF: React.FC<PDFProps> = ({
  user,
  resume,
  title,
  language,
  primaryColor = DEFAULT_PRIMARY_COLOR,
  pageSize = DEFAULT_PAGE_SIZE,
  headerFont = DEFAULT_HEADER_FONT,
  textFont = DEFAULT_TEXT_FONT,
}) => {
  const styles = useMemo(
    () => ({
      ...createCoverLetterStyles(
        primaryColor || '#2F7D7A',
        headerFont,
        textFont,
      ),
      ...specificCoverLetterStyles(
        primaryColor || '#2F7D7A',
        headerFont,
        textFont,
      ),
    }),
    [primaryColor, headerFont, textFont],
  );

  const paragraphs = resume.coverLetter?.split('\n\n') || [];

  const date = currentDateString(language);

  return (
    <CoverLetterPDF
      user={user}
      title={title}
      language={language}
      primaryColor={primaryColor}
      pageSize={pageSize}
      headerFont={headerFont}
      textFont={textFont}
      customStyles={styles}
    >
      <View style={styles.container}>
        <View style={styles.sidebar}>
          <Text style={styles.sidebarHeader}>
            {user.profile?.firstName} {user.profile?.lastName}
          </Text>
          <Text style={styles.sidebarText}>{user.profile?.phone}</Text>
          <Text style={styles.sidebarText}>{user.profile?.address}</Text>

          <Link
            src={`mailto:${user.profile?.displayEmail}`}
            style={styles.linkStyle}
          >
            {user.profile?.displayEmail}
          </Link>
          <Link src={user.profile?.website} style={styles.linkStyle}>
            {user.profile?.website}
          </Link>
        </View>

        <View style={styles.content}>
          <View fixed style={styles.headerPadding}></View>
          <Text style={styles.dateSection}>
            <Text style={styles.text}>{date}</Text>
          </Text>
          <Text style={styles.specificHeader}>
            {user.profile?.firstName} {user.profile?.lastName}
          </Text>
          <Text style={styles.subHeader}>{title}</Text>

          <View>
            {paragraphs.map((paragraph, idx) => (
              <View key={idx} style={styles.contentSection}>
                <Text style={styles.text}>{paragraph}</Text>
              </View>
            ))}
          </View>
        </View>
      </View>
    </CoverLetterPDF>
  );
};

export const coverLetterTemplateSideBar: TemplateType = {
  label: 'Side Bar',
  component: CoverLetterSideBarPDF,
  preview: previewWebP,
  previewFallback: previewPng,
};
