import { Styles, Text, View } from '@react-pdf/renderer';
import React from 'react';

interface BulletPointsProps {
  points: string[];
  styles: Styles;
  addPageBreakPadding?: boolean;
}

export const BulletPoints: React.FC<BulletPointsProps> = ({
  points,
  styles,
  addPageBreakPadding = false,
}) =>
  Array.isArray(points) && (
    <View style={styles.bulletList} wrap={true}>
      {points.map((point, index) => (
        <View key={index} style={styles.bulletPointContainer} wrap={false}>
          <Text style={styles.bulletPointSymbol}>•</Text>
          <Text style={styles.bulletPointText}>{point}</Text>
        </View>
      ))}
      {addPageBreakPadding && <View fixed style={{ height: 20 }}></View>}
    </View>
  );

export const BulletPointsWithPadding: React.FC<
  Omit<BulletPointsProps, 'addPageBreakPadding'>
> = (props) => <BulletPoints {...props} addPageBreakPadding />;
