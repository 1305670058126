import {
  ActionIcon,
  Button,
  Group,
  Modal,
  Paper,
  Stack,
  Text,
  Textarea,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconPencil } from '@tabler/icons-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IResumeWithSuggestion } from '../../models/Resume';
import { useUpdateResumeJsonMutation } from '../../queries/ResumeQueries';

export const Introduction: React.FC<{
  resume: IResumeWithSuggestion;
  hidden: boolean;
}> = ({ resume, hidden }) => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);

  const form = useForm({
    initialValues: {
      intro: resume.suggestion.intro,
    },
    validate: {
      intro: (value) => (!value ? t('resume.intro.introRequired') : null),
    },
  });

  const updateResumeMutation = useUpdateResumeJsonMutation(resume.id);

  const handleSave = () => {
    if (form.isValid()) {
      const updatedResumeJson = {
        ...resume.suggestion,
        intro: form.values.intro,
      };
      updateResumeMutation.mutate(updatedResumeJson, {
        onSuccess: () => {
          setOpened(false);
        },
      });
    }
  };

  return (
    <Stack gap="xs">
      <Group>
        <Title order={3}>{t('resume.intro.title')}</Title>
        <ActionIcon
          variant="subtle"
          onClick={() => {
            setOpened(true);
          }}
          disabled={hidden}
          size="sm"
        >
          <IconPencil />
        </ActionIcon>
      </Group>

      <Paper px="md" py="xs">
        {resume.suggestion.intro.split('\n').map(
          (line, idx) =>
            line && (
              <Text mb="0.75rem" key={idx}>
                {line}
              </Text>
            ),
        )}
      </Paper>

      <Modal
        opened={opened}
        onClose={() => {
          setOpened(false);
        }}
        title={t('resume.intro.editIntroduction')}
        centered
        size="xl"
      >
        <form onSubmit={form.onSubmit(handleSave)}>
          <Textarea
            label={t('resume.intro.title')}
            {...form.getInputProps('intro')}
            onBlur={() => form.validateField('intro')}
            minRows={6}
            autosize
          />
          <Group justify="flex-end" mt="md">
            <Button
              variant="outline"
              onClick={() => {
                setOpened(false);
              }}
            >
              {t('labels.cancel')}
            </Button>
            <Button type="submit" disabled={!form.isValid()}>
              {t('labels.save')}
            </Button>
          </Group>
        </form>
      </Modal>
    </Stack>
  );
};
