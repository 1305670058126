import { Anchor, Button, Group, Stack, Text, Title } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconAbacus, IconRotate2, IconTrash, IconX } from '@tabler/icons-react';
import { HTTPError } from 'ky';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRouteURL } from '../../AppRouteURL';
import { IResume, ResumeStatus } from '../../models/Resume';
import { useSuggestionMutation } from '../../queries/ResumeQueries';
import { DeleteResumeButton } from '../DeleteResumeButton/DeleteResumeButton';

export const SettingsSection: React.FC<{ resume: IResume }> = ({ resume }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const suggestionMutation = useSuggestionMutation(resume.id);

  const regenerateResume = async () => {
    try {
      resume.status = ResumeStatus.Generating;
      await suggestionMutation.mutateAsync();
      showNotification({
        id: 'suggestion',
        title: t('view.regeneratingTitle'),
        message: t('view.regeneratingMessage'),
        icon: <IconAbacus />,
        loading: true,
        autoClose: 5000,
      });
    } catch (error) {
      console.dir(error);
      let message = <Text>{t('view.regeneratingFailed')}</Text>;
      if (error instanceof HTTPError && error.response.status === 429) {
        message = (
          <Group>
            <Text>{t('view.rateLimitExceeded')}</Text>
            <Anchor href={AppRouteURL.account}>
              {t('view.upgradeAccount')}
            </Anchor>
          </Group>
        );
      }

      showNotification({
        title: t('view.regeneratingErrorTitle'),
        message,
        color: 'red',
        icon: <IconX />,
        loading: false,
        autoClose: false,
      });
    }
  };

  return (
    <Stack>
      <Title order={3}>{t('view.settings')}</Title>
      <Group>
        <DeleteResumeButton
          resume={resume}
          beforeDelete={() => {
            navigate(AppRouteURL.resumes.list);
          }}
        >
          <Button color="red" variant="outline" leftSection={<IconTrash />}>
            {t('view.deleteLabel')}
          </Button>
        </DeleteResumeButton>
        <Button
          disabled={
            resume.status !== ResumeStatus.Ok &&
            resume.status !== ResumeStatus.Error &&
            resume.status !== ResumeStatus.InvalidURL
          }
          variant="outline"
          leftSection={<IconRotate2 />}
          onClick={() => regenerateResume()}
        >
          {t('view.regenerateLabel')}
        </Button>
      </Group>
    </Stack>
  );
};
