import {
  Anchor,
  Box,
  Button,
  ColorInput,
  Container,
  Grid,
  Group,
  Select,
  Stack,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery, useToggle } from '@mantine/hooks';
import {
  Document,
  Font,
  Page,
  Text as PDFText,
  usePDF,
} from '@react-pdf/renderer';
import { IconDownload, IconRotate2 } from '@tabler/icons-react';
import type { HyphenationFunctionSync } from 'hyphen';
import { hyphenateSync as hyphenateDe } from 'hyphen/de-1996';
import { hyphenateSync as hyphenateEn } from 'hyphen/en-us';
import { hyphenateSync as hyphenateEs } from 'hyphen/es';
import { hyphenateSync as hyphenateFr } from 'hyphen/fr';
import { hyphenateSync as hyphenateIt } from 'hyphen/it';
import { hyphenateSync as hyphenatePt } from 'hyphen/pt';
import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AppRouteURL } from '../../AppRouteURL';
import { IResume, IResumeJSON } from '../../models/Resume';
import { IUser, SubscriptionType } from '../../models/User';

import { coverLetterTemplateBasic } from '../../pdf/CoverLetterBasicPDF/CoverLetterBasicPDF';
import { coverLetterTemplateCleanColumn } from '../../pdf/CoverLetterCleanColumnPDF/CoverLetterCleanColumnPDF';
import { coverLetterTemplateGraphic } from '../../pdf/CoverLetterGraphicPDF/CoverLetterGraphicPDF';
import { coverLetterTemplateSideBar } from '../../pdf/CoverLetterSideBarPDF/CoverLetterSideBarPDF';
import { coverLetterTemplateStylish } from '../../pdf/CoverLetterStylishPDF/CoverLetterStylishPDF';
import { PDFProps } from '../../pdf/PDFProps';
import { resumeTemplateBasic } from '../../pdf/ResumeBasicPDF/ResumeBasicPDF';
import { resumeTemplateCleanColumn } from '../../pdf/ResumeCleanColumnPDF/ResumeCleanColumnPDF';
import { resumeTemplateGraphic } from '../../pdf/ResumeGraphicPDF/ResumeGraphicPDF';
import { resumeTemplateSideBar } from '../../pdf/ResumeSideBarPDF/ResumeSideBarPDF';
import {
  DEFAULT_HEADER_FONT,
  DEFAULT_TEXT_FONT,
  resumeTemplateStylish,
} from '../../pdf/ResumeStylishPDF/ResumeStylishPDF';
import { getLogger } from '../../services/Logger';
import { DocumentTypeToggle } from './DocumentTypeToggle';
import FontSelector from './FontSelector';
import { ResumePDFViewer } from './ResumePDFViewer';
import { TemplateModal } from './TemplateModal';
import { TemplateSelector } from './TemplateSelector';

const logger = getLogger('ResumeDocumentView');

export interface TemplateEntry {
  label: string;
  component: React.FC<PDFProps>;
  preview?: string;
  previewFallback: string;
}

export type DocumentType = 'resume' | 'coverLetter';

const resumeTemplates: TemplateEntry[] = [
  resumeTemplateBasic,
  resumeTemplateSideBar,
  resumeTemplateGraphic,
  resumeTemplateCleanColumn,
  resumeTemplateStylish,
];

const coverLetterTemplates: TemplateEntry[] = [
  coverLetterTemplateBasic,
  coverLetterTemplateSideBar,
  coverLetterTemplateGraphic,
  coverLetterTemplateCleanColumn,
  coverLetterTemplateStylish,
];

const pageSizes = [
  { label: 'A4', value: 'A4' },
  { label: 'Letter', value: 'Letter' },
];

const hyphenators = new Map<string, HyphenationFunctionSync>();
hyphenators.set('en', hyphenateEn);
hyphenators.set('de', hyphenateDe);
hyphenators.set('es', hyphenateEs);
hyphenators.set('fr', hyphenateFr);
hyphenators.set('it', hyphenateIt);
hyphenators.set('pt', hyphenatePt);

export const ResumeDocumentView: React.FC<{
  resumeId: IResume['id'];
  resume: IResumeJSON;
  user: IUser;
  language: string;
  title?: string;
  coverLetter?: string;
}> = ({ resumeId, resume, user, language, title }) => {
  const { t } = useTranslation();
  const [instance, updateInstance] = usePDF();
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateEntry>(
    resumeTemplates[0],
  );
  const [selectedCoverLetterTemplate, setSelectedCoverLetterTemplate] =
    useState<TemplateEntry>(coverLetterTemplates[0]);
  const [primaryColor, setPrimaryColor] = useState<string | undefined>(
    '#2F7D7A',
  );
  const [pageSize, setPageSize] = useState(pageSizes[0].value);
  const [headerFont, setHeaderFont] = useState(DEFAULT_HEADER_FONT);
  const [textFont, setTextFont] = useState(DEFAULT_TEXT_FONT);
  const [selectedFontCombination, setSelectedFontCombination] = useState(0);
  const [modalOpened, setModalOpened] = useState(false);
  const [documentType, toggleDocumentType] = useToggle<DocumentType>([
    'resume',
    'coverLetter',
  ]);

  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  const enableHyphenation = false;

  useEffect(() => {
    if (enableHyphenation) {
      logger.debug(`Setting hyphenator for language ${language}`);
      const hyphenate = hyphenators.get(language);
      Font.registerHyphenationCallback(
        (word) => hyphenate?.(word).split('\u00AD') ?? [word],
      );
    } else {
      logger.debug('Disabling hyphenation');
      Font.registerHyphenationCallback((word) => [word]);
    }
  }, [language]);

  const handleFontChange = (header: string, text: string, index: number) => {
    setHeaderFont(header);
    setTextFont(text);
    setSelectedFontCombination(index);
  };

  const handleReset = () => {
    setSelectedTemplate(resumeTemplates[0]);
    setSelectedCoverLetterTemplate(coverLetterTemplates[0]);
    setPrimaryColor('#2F7D7A');
    setPageSize(pageSizes[0].value);
    setHeaderFont(DEFAULT_HEADER_FONT);
    setTextFont(DEFAULT_TEXT_FONT);
    setSelectedFontCombination(0);
  };

  const handleTemplateSelect = (template: TemplateEntry) => {
    if (documentType === 'coverLetter') {
      setSelectedCoverLetterTemplate(template);
    } else {
      setSelectedTemplate(template);
    }
    setModalOpened(false);
  };

  const templates =
    documentType === 'resume' ? resumeTemplates : coverLetterTemplates;

  const downloadFileName =
    (t(`download.${documentType}FileTitle`) as string) +
    (title ? ` - ${title}` : '') +
    '.pdf';

  const currentTemplate =
    documentType === 'resume' ? selectedTemplate : selectedCoverLetterTemplate;

  useEffect(() => {
    const templateDocument = (
      <Suspense
        fallback={
          <Document>
            <Page>
              <PDFText>{t('view.pdf.rendering')}</PDFText>
            </Page>
          </Document>
        }
      >
        <currentTemplate.component
          resume={resume}
          user={user}
          resumeId={resumeId}
          language={language}
          title={title}
          primaryColor={primaryColor}
          pageSize={pageSize}
          headerFont={headerFont}
          textFont={textFont}
        />
      </Suspense>
    );
    setTimeout(() => {
      updateInstance(templateDocument);
    }, 1);
  }, [
    currentTemplate,
    resume,
    user,
    resumeId,
    language,
    title,
    primaryColor,
    pageSize,
    headerFont,
    textFont,
  ]);

  return (
    <Container fluid>
      <Grid mt="xl">
        <Grid.Col span={{ base: 12, sm: 12, md: 12, lg: 6 }}>
          <Stack gap="3rem">
            <Box>
              <Title order={2}>{t('view.pdf.title')}</Title>
              {user.subscriptionType === SubscriptionType.FREE && (
                <Anchor component={Link} c="dimmed" to={AppRouteURL.subscribe}>
                  {t('view.pdf.upgrade')}
                </Anchor>
              )}
            </Box>

            <Group justify="space-between">
              <DocumentTypeToggle
                documentType={documentType}
                onToggle={toggleDocumentType}
              />
              <Button
                loading={instance.loading}
                component="a"
                href={instance.url || undefined}
                download={downloadFileName}
                leftSection={<IconDownload />}
              >
                {t('view.pdf.downloadButtonTitle')}
              </Button>
            </Group>

            <TemplateSelector
              currentTemplate={currentTemplate}
              onOpenModal={() => {
                setModalOpened(true);
              }}
            />

            <FontSelector
              onFontChange={handleFontChange}
              selectedCombinationIndex={selectedFontCombination}
            />

            <Group justify="space-between">
              <Select
                label={
                  <Title order={6} c="dimmed">
                    {t('view.pdf.pageSizeLabel')}
                  </Title>
                }
                data={pageSizes}
                value={pageSize}
                onChange={(value) => {
                  setPageSize(value || pageSizes[0].value);
                }}
              />
              <ColorInput
                label={
                  <Title order={6} c="dimmed">
                    {t('view.pdf.primaryColorLabel')}
                  </Title>
                }
                value={primaryColor}
                onChange={setPrimaryColor}
              />
            </Group>
            <Button
              leftSection={<IconRotate2 />}
              onClick={handleReset}
              variant="outline"
            >
              {t('view.pdf.resetButtonTitle')}
            </Button>
          </Stack>
        </Grid.Col>

        <Grid.Col span={{ base: 12, sm: 12, md: 12, lg: 6 }}>
          <Box
            p="sm"
            h="100%"
            mih={690}
            style={{
              border: `1px solid ${theme.colors.gray[4]}`,
              borderRadius: theme.radius.md,
            }}
          >
            {!instance.loading && (
              <ResumePDFViewer
                width="100%"
                height="100%"
                showToolbar={true}
                url={instance.url}
              />
            )}
          </Box>
        </Grid.Col>
      </Grid>

      <TemplateModal
        opened={modalOpened}
        onClose={() => {
          setModalOpened(false);
        }}
        templates={templates}
        onTemplateSelect={handleTemplateSelect}
        isMobile={!!isMobile}
      />
    </Container>
  );
};
