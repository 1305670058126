import {
  Button,
  Group,
  Modal,
  Paper,
  Stack,
  Text,
  Timeline,
  Title,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconPlus, IconTrash, IconX } from '@tabler/icons-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IResumeJSONEducation,
  IResumeWithSuggestion,
} from '../../models/Resume';
import { useUpdateResumeJsonMutation } from '../../queries/ResumeQueries';

import classes from './Education.module.css';
import { EducationModal } from './EducationModal';
import { EducationSection } from './EducationSection';

export const Education: React.FC<{
  resume: IResumeWithSuggestion;
  hidden: boolean;
}> = ({ resume, hidden }) => {
  const educations = resume.suggestion.educations;
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const [currentEducation, setCurrentEducation] =
    useState<IResumeJSONEducation | null>(null);
  const [editedEducation, setEditedEducation] = useState<IResumeJSONEducation>({
    start: '',
    end: '',
    degree: '',
    fieldOfStudy: '',
    schoolName: '',
    notes: [''],
  });
  const [confirmDeleteOpened, setConfirmDeleteOpened] = useState(false);
  const [educationToDelete, setEducationToDelete] = useState<number | null>(
    null,
  );

  const updateResumeMutation = useUpdateResumeJsonMutation(resume.id);

  const handleEditClick = (education: IResumeJSONEducation) => {
    setCurrentEducation(education);
    setEditedEducation({ ...education });
    setOpened(true);
  };

  const handleSave = (values: IResumeJSONEducation) => {
    const updatedEducations = currentEducation
      ? educations.map((edu) => (edu === currentEducation ? values : edu))
      : [...educations, values];
    const updatedResumeJson = {
      ...resume.suggestion,
      educations: updatedEducations,
    };
    updateResumeMutation.mutate(updatedResumeJson, {
      onSuccess: () => {
        setOpened(false);
      },
    });
  };

  const addEducation = () => {
    setCurrentEducation(null);
    setEditedEducation({
      start: '',
      end: '',
      degree: '',
      fieldOfStudy: '',
      schoolName: '',
      notes: [''],
    });
    setOpened(true);
  };

  const confirmRemoveEducation = (index: number) => {
    setEducationToDelete(index);
    setConfirmDeleteOpened(true);
  };

  const handleRemoveEducation = () => {
    if (educationToDelete !== null && educations.length > 1) {
      const updatedEducations = educations.filter(
        (_, idx) => idx !== educationToDelete,
      );
      const updatedResumeJson = {
        ...resume.suggestion,
        educations: updatedEducations,
      };
      updateResumeMutation.mutate(updatedResumeJson, {
        onSuccess: () => {
          setConfirmDeleteOpened(false);
        },
      });
    } else {
      showNotification({
        title: t('resume.education.cannotDelete'),
        message: t('resume.education.cannotDeleteLastEducation'),
        color: 'red',
      });
    }
  };

  const moveEducationUp = (index: number) => {
    if (index > 0) {
      const newEducations = [...educations];
      [newEducations[index - 1], newEducations[index]] = [
        newEducations[index],
        newEducations[index - 1],
      ];
      const updatedResumeJson = {
        ...resume.suggestion,
        educations: newEducations,
      };
      updateResumeMutation.mutate(updatedResumeJson);
    }
  };

  const moveEducationDown = (index: number) => {
    if (index < educations.length - 1) {
      const newEducations = [...educations];
      [newEducations[index], newEducations[index + 1]] = [
        newEducations[index + 1],
        newEducations[index],
      ];
      const updatedResumeJson = {
        ...resume.suggestion,
        educations: newEducations,
      };
      updateResumeMutation.mutate(updatedResumeJson);
    }
  };

  return (
    <Stack>
      <Group justify="space-between">
        <Title order={3}>{t('resume.education.title')}</Title>
        <Button
          leftSection={<IconPlus size={16} />}
          onClick={addEducation}
          variant="outline"
          size="xs"
          disabled={hidden}
        >
          {t('resume.education.addEducation')}
        </Button>
      </Group>
      <Paper p="lg">
        <Timeline active={educations.length}>
          {educations.map((section, index) => (
            <Timeline.Item
              key={index}
              className={classes.hoverHighlight}
              title={
                <Group justify="space-between">
                  <Title order={4}>{section.fieldOfStudy}</Title>
                  <Title order={4}>
                    {section.start}
                    {section.end && ` - ${section.end}`}
                  </Title>
                </Group>
              }
              bullet={<IconCheck size={18} />}
            >
              <EducationSection
                section={section}
                index={index}
                hidden={hidden}
                onEdit={() => {
                  handleEditClick(section);
                }}
                onRemove={() => {
                  confirmRemoveEducation(index);
                }}
                onMoveUp={
                  index > 0
                    ? () => {
                        moveEducationUp(index);
                      }
                    : undefined
                }
                onMoveDown={
                  index < educations.length - 1
                    ? () => {
                        moveEducationDown(index);
                      }
                    : undefined
                }
              />
            </Timeline.Item>
          ))}
        </Timeline>
      </Paper>

      <EducationModal
        opened={opened}
        onClose={() => {
          setOpened(false);
        }}
        onSave={handleSave}
        initialValues={editedEducation}
      />

      <Modal
        opened={confirmDeleteOpened}
        onClose={() => {
          setConfirmDeleteOpened(false);
        }}
        title={t('resume.education.confirmDelete')}
        centered
      >
        <Text>{t('resume.education.confirmDeleteMessage')}</Text>
        <Group justify="flex-end" mt="md">
          <Button
            variant="outline"
            onClick={() => {
              setConfirmDeleteOpened(false);
            }}
          >
            <IconX />
            {t('labels.cancel')}
          </Button>
          <Button color="red" onClick={handleRemoveEducation}>
            <IconTrash />
            {t('labels.delete')}
          </Button>
        </Group>
      </Modal>
    </Stack>
  );
};
