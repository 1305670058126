import {
  ActionIcon,
  Anchor,
  Flex,
  Group,
  Paper,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from '@mantine/core';
import {
  IconBrandGithub,
  IconBrandLinkedin,
  IconGlobe,
  IconHome,
  IconMail,
  IconPencil,
  IconPhone,
} from '@tabler/icons-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRouteURL } from '../../AppRouteURL';
import { IUser } from '../../models/User';

export const ContactInfo: React.FC<{ user: IUser }> = ({ user }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Stack>
      <Group>
        <Title order={3}>{t('resume.contactTitle')}</Title>
        <ActionIcon
          variant="subtle"
          size="sm"
          onClick={() => {
            navigate(AppRouteURL.profileTabBasic);
          }}
        >
          <IconPencil />
        </ActionIcon>
      </Group>

      <Paper p="md">
        <Title order={4} pb="sm">
          {user.profile?.firstName} {user.profile?.lastName}
        </Title>

        <Stack gap="sm">
          <Flex
            gap="md"
            justify="space-between"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Group gap="xs">
              <ThemeIcon>
                <IconMail />
              </ThemeIcon>{' '}
              <Text>{user.profile?.displayEmail || user.email}</Text>
            </Group>

            {user.profile?.website && (
              <Group gap="xs">
                <ThemeIcon>
                  <IconGlobe />
                </ThemeIcon>
                <Anchor target="_blank" href={user.profile.website}>
                  {user.profile.website}
                </Anchor>
              </Group>
            )}

            {user.profile?.linkedIn && (
              <Group gap="xs">
                <ThemeIcon>
                  <IconBrandLinkedin />
                </ThemeIcon>
                <Anchor target="_blank" href={user.profile.linkedIn}>
                  {user.profile.linkedIn}
                </Anchor>
              </Group>
            )}

            {user.profile?.github && (
              <Group gap="xs">
                <ThemeIcon>
                  <IconBrandGithub />
                </ThemeIcon>
                <Anchor target="_blank" href={user.profile.github}>
                  {user.profile.github}
                </Anchor>
              </Group>
            )}
          </Flex>

          {user.profile?.phone && (
            <Group gap="xs">
              <ThemeIcon>
                <IconPhone />
              </ThemeIcon>
              <Text>{user.profile.phone}</Text>
            </Group>
          )}

          {user.profile?.address && (
            <Group gap="xs">
              <ThemeIcon>
                <IconHome size={16} />
              </ThemeIcon>
              <Text>{user.profile.address}</Text>
            </Group>
          )}
        </Stack>
      </Paper>
    </Stack>
  );
};
