import { Font } from '@react-pdf/renderer';
import MerriweatherUrlMerriweatheritalic300 from './fonts/Merriweather/Merriweather-italic-300.ttf?url';
import MerriweatherUrlMerriweatheritalic400 from './fonts/Merriweather/Merriweather-italic-400.ttf?url';
import MerriweatherUrlMerriweatheritalic700 from './fonts/Merriweather/Merriweather-italic-700.ttf?url';
import MerriweatherUrlMerriweatheritalic900 from './fonts/Merriweather/Merriweather-italic-900.ttf?url';
import MerriweatherUrlMerriweathernormal300 from './fonts/Merriweather/Merriweather-normal-300.ttf?url';
import MerriweatherUrlMerriweathernormal400 from './fonts/Merriweather/Merriweather-normal-400.ttf?url';
import MerriweatherUrlMerriweathernormal700 from './fonts/Merriweather/Merriweather-normal-700.ttf?url';
import MerriweatherUrlMerriweathernormal900 from './fonts/Merriweather/Merriweather-normal-900.ttf?url';

type FontWeight = 'normal' | 'bold' | 'light' | undefined;
type FontStyle = 'normal' | 'italic' | 'oblique' | undefined;

Font.register({
  family: 'Merriweather',
  fonts: [
    {
      src: MerriweatherUrlMerriweatheritalic300,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '300' as FontWeight,
    },
    {
      src: MerriweatherUrlMerriweatheritalic400,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '400' as FontWeight,
    },
    {
      src: MerriweatherUrlMerriweatheritalic700,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '700' as FontWeight,
    },
    {
      src: MerriweatherUrlMerriweatheritalic900,
      fontStyle: 'italic' as FontStyle,
      fontWeight: '900' as FontWeight,
    },
    {
      src: MerriweatherUrlMerriweathernormal300,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '300' as FontWeight,
    },
    {
      src: MerriweatherUrlMerriweathernormal400,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '400' as FontWeight,
    },
    {
      src: MerriweatherUrlMerriweathernormal700,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '700' as FontWeight,
    },
    {
      src: MerriweatherUrlMerriweathernormal900,
      fontStyle: 'normal' as FontStyle,
      fontWeight: '900' as FontWeight,
    },
  ],
});
