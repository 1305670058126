import { Box, Container, Group, Stack, Text, ThemeIcon } from '@mantine/core';
import { IconBulb } from '@tabler/icons-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PRODUCT_NAME } from '../../Constants';

import classes from './FancyInfoBox.module.css';

const FancyInfoBox: React.FC<{ infoText: string }> = ({ infoText }) => {
  const { t } = useTranslation();

  return (
    <Container className={classes.container}>
      <Stack gap="sm">
        <Group wrap="nowrap">
          <ThemeIcon
            size="xl"
            radius="xl"
            color="green"
            className={classes.icon}
          >
            <IconBulb size={28} />
          </ThemeIcon>
          <Text size="lg" fw={500}>
            {t('view.whatTheAIThinks', { brand: PRODUCT_NAME })}
          </Text>
        </Group>

        <Box>
          {infoText.split('\n').map(
            (line, idx) =>
              line && (
                <Text size="md" mb="0.75rem" key={idx}>
                  {line}
                </Text>
              ),
          )}
        </Box>
      </Stack>
    </Container>
  );
};

export default FancyInfoBox;
