import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Api from '../Api';
import { ApiRoutes } from '../ApiRoutes';
import { IResume, IResumeJSON } from '../models/Resume';

export type IUpdateResumeDto = Pick<
  IResume,
  'title' | 'info' | 'jobAdType' | 'ad' | 'jobAdUrl' | 'language' | 'style'
>;

export type IUpdateResumeJsonDto = IResumeJSON;

export const QUERY_KEY_RESUMES = ['resumes'];

const filterValues = (dto: IUpdateResumeDto) => ({
  jobAdType: dto.jobAdType || undefined,
  jobAdUrl: dto.jobAdUrl || undefined,
  ad: dto.ad || undefined,
  language: dto.language || undefined,
  info: dto.info || undefined,
  title: dto.title || undefined,
  style: dto.style || undefined,
});

export const useCreateResumeMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (newResume: IUpdateResumeDto) =>
      Api.ky
        .post(ApiRoutes.Resumes, {
          json: filterValues(newResume),
        })
        .json<IResume>(),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY_RESUMES,
      }),
  });
};

export const useUpdateResumeMutation = (resumeId: IResume['id']) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (updatedResume: IUpdateResumeDto) =>
      Api.ky
        .put(`${ApiRoutes.Resumes}/${resumeId}`, {
          json: filterValues(updatedResume),
        })
        .json<IResume>(),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY_RESUMES,
      }),
  });
};

export const useUpdateResumeJsonMutation = (resumeId: IResume['id']) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (updatedResumeJson: IUpdateResumeJsonDto) =>
      Api.ky
        .put(`${ApiRoutes.Resumes}/${resumeId}/json`, {
          json: { suggestion: updatedResumeJson },
        })
        .json<IResume>(),
    onSuccess: (data) =>
      queryClient.setQueryData([...QUERY_KEY_RESUMES, resumeId], data),
  });
};

export const useSuggestionMutation = (resumeId: IResume['id']) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () =>
      Api.ky
        .post(`${ApiRoutes.Resumes}/${resumeId}/new-suggestion`)
        .json<IResume>(),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: QUERY_KEY_RESUMES,
      }),
  });
};

export const useResumeQuery = (resumeId?: IResume['id']) =>
  useQuery({
    queryKey: [...QUERY_KEY_RESUMES, resumeId],

    queryFn: () =>
      Api.ky.get(`${ApiRoutes.Resumes}/${resumeId ?? ''}`).json<IResume>(),

    enabled: !!resumeId,
  });

export const useResumesQuery = () =>
  useQuery({
    queryKey: QUERY_KEY_RESUMES,

    queryFn: () =>
      Api.ky
        .get(ApiRoutes.Resumes)
        .json<{ resumes: IResume[] }>()
        .then(({ resumes }) =>
          resumes.sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
          ),
        ),
  });
