import {
  ActionIcon,
  Center,
  Container,
  Group,
  Select,
  Stack,
  Tabs,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconCaretLeft, IconCaretRight, IconPencil } from '@tabler/icons-react';
import React, { lazy, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { AppRouteURL } from '../../AppRouteURL';
import { Bowl } from '../../components/Bowl/Bowl';
import { CoverLetterView } from '../../components/CoverLetterView/CoverLetterView';
import { LoadingScreen } from '../../components/LoadingScreen/LoadingScreen';
import { ResumeDocumentView } from '../../components/ResumeDocumentView/ResumeDocumentView';
import { ResumeHistoryView } from '../../components/ResumeHistoryView/ResumeHistoryView';
import { ResumeInfoView } from '../../components/ResumeInfoView/ResumeInfoView';
import { ResumeView } from '../../components/ResumeView/ResumeView';
import { useProfileQuery } from '../../models/ProfileQueries';
import { IResumeWithSuggestion } from '../../models/Resume';
import { SubscriptionType, useUserQuery } from '../../models/User';
import { useResumeQuery } from '../../queries/ResumeQueries';
import { features } from '../../services/Features';
import { getLogger } from '../../services/Logger';
import { TitleModal } from './TitleModal';

const logger = getLogger('ViewResumePage');

const InterviewTab = lazy(
  () =>
    import(
      /* webpackChunkName: "view-resume" */ '../../components/InterviewTab/InterviewTab'
    ),
);

enum TABS {
  RESUME = 'resume',
  LETTER = 'letter',
  INFO = 'info',
  HISTORY = 'history',
  PDF = 'pdf',
  INTERVIEW = 'interview',
}

const SuggestionNotFound = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Title order={2}>{t('view.pageTitle')}</Title>
      <Text>{t('view.noResumeFound')}</Text>
    </Container>
  );
};

export const ViewResumePage: React.FC = () => {
  const { resumeId, tab } = useParams();
  const theme = useMantineTheme();
  const largeScreen = useMediaQuery(`(min-width: ${theme.breakpoints.lg}px)`);
  const navigate = useNavigate();
  const [titleModalOpen, setTitleModalOpen] = useState(false);

  if (!resumeId) {
    return <SuggestionNotFound />;
  }

  const { data: user, isPending: userLoading } = useUserQuery();
  const { data: profile, isPending: profileLoading } = useProfileQuery();
  const { data: resumeData, isPending: resumeLoading } =
    useResumeQuery(resumeId);

  const { t, i18n } = useTranslation();

  const menuData = useMemo(() => {
    const entries = [
      { value: TABS.RESUME, label: t('view.resumeTab') },
      { value: TABS.LETTER, label: t('view.coverLetterTab') },
      { value: TABS.INFO, label: t('view.infoTab') },
      { value: TABS.HISTORY, label: t('view.historyTab') },
      { value: TABS.PDF, label: t('view.pdfTab') },
    ];

    if (features.enabled('interview')) {
      entries.push({ value: TABS.INTERVIEW, label: t('view.interviewTab') });
    }
    return entries;
  }, [i18n.language]);

  if (
    (!user && userLoading) ||
    (!profile && profileLoading) ||
    (!resumeData && resumeLoading)
  ) {
    return <LoadingScreen />;
  }

  if (!resumeData || !user || !profile) {
    return <SuggestionNotFound />;
  }

  if (!resumeData.suggestion) {
    return (
      <Navigate
        to={`${AppRouteURL.resumes.create}/0?resumeId=${resumeId}`}
        replace={true}
      />
    );
  }

  const resume = resumeData as IResumeWithSuggestion;

  const hidden =
    !user ||
    (!resume.isPaid && user.subscriptionType === SubscriptionType.FREE);

  user.profile = profile;

  const currentMenuEntry =
    menuData.find((entry) => entry.value === tab) || menuData[0];
  const currentMenuIndex =
    currentMenuEntry && menuData.indexOf(currentMenuEntry);
  const nextMenuEntry =
    currentMenuIndex === menuData.length - 1
      ? menuData[0]
      : menuData[currentMenuIndex + 1];
  const previousMenuEntry =
    currentMenuIndex === 0
      ? menuData[menuData.length - 1]
      : menuData[currentMenuIndex - 1];

  return (
    <Container
      size="xl"
      p={largeScreen ? 'md' : 0}
      mb={largeScreen ? 'xl' : 'xs'}
    >
      <Bowl title={t('view.pageTitle')} />

      {/*TODO: Rework tour for ViewResumePage*/}

      <Center>
        <Group>
          <ActionIcon
            variant="subtle"
            c="dimmed"
            mb="md"
            onClick={() => {
              navigate(
                `${AppRouteURL.resumes.view}/${resumeId}/${previousMenuEntry.value}`,
              );
            }}
          >
            <IconCaretLeft />
          </ActionIcon>
          <Select
            maw="12rem"
            size="xs"
            mb="md"
            comboboxProps={{ shadow: 'md' }}
            data={menuData}
            value={tab || TABS.RESUME}
            onChange={(value) => {
              navigate(`${AppRouteURL.resumes.view}/${resumeId}/${value}`);
            }}
            leftSectionPointerEvents="none"
          />
          <ActionIcon
            variant="subtle"
            c="dimmed"
            mb="md"
            onClick={() => {
              navigate(
                `${AppRouteURL.resumes.view}/${resumeId}/${nextMenuEntry.value}`,
              );
            }}
          >
            <IconCaretRight />
          </ActionIcon>
        </Group>
      </Center>

      <Group justify="center" wrap="nowrap" gap="xs">
        <Title order={2} lineClamp={1} my="lg">
          {resume.title || t('view.pageTitle')}
        </Title>
        <ActionIcon
          variant="subtle"
          size="sm"
          onClick={() => {
            setTitleModalOpen(true);
          }}
        >
          <IconPencil />
        </ActionIcon>
      </Group>

      <Stack gap="lg">
        <Tabs
          value={tab || TABS.RESUME}
          onChange={(value) => {
            navigate(`${AppRouteURL.resumes.view}/${resumeId}/${value}`);
          }}
          orientation={largeScreen ? 'vertical' : 'horizontal'}
          radius="lg"
          keepMounted={false}
        >
          <Tabs.Panel value={TABS.RESUME} py="md">
            <ResumeView resume={resume} user={user} hidden={hidden} />
          </Tabs.Panel>

          <Tabs.Panel value={TABS.LETTER} py="md">
            <CoverLetterView resume={resume} hidden={hidden} />
          </Tabs.Panel>

          <Tabs.Panel value={TABS.INFO} py="md">
            <ResumeInfoView resume={resume} hidden={hidden} />
          </Tabs.Panel>

          <Tabs.Panel value={TABS.HISTORY} py="md">
            <ResumeHistoryView resume={resume} />
          </Tabs.Panel>

          <Tabs.Panel value={TABS.PDF} py="md">
            <ResumeDocumentView
              resume={resume.suggestion}
              user={user}
              language={resume.language as string}
              resumeId={resume.id}
              title={resume.title}
            />
          </Tabs.Panel>

          {features.enabled('interview') && (
            <Tabs.Panel value={TABS.INTERVIEW} py="md">
              <InterviewTab resume={resume} />
            </Tabs.Panel>
          )}
        </Tabs>
      </Stack>

      {resume && (
        <TitleModal
          opened={titleModalOpen}
          onClose={() => {
            setTitleModalOpen(false);
          }}
          resume={resume}
        />
      )}
    </Container>
  );
};

export default ViewResumePage;
