import { Center, rem, SegmentedControl } from '@mantine/core';
import { IconFileCv, IconMailSpark } from '@tabler/icons-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentType } from './ResumeDocumentView';

interface DocumentTypeToggleProps {
  documentType: DocumentType;
  onToggle: () => void;
}

export const DocumentTypeToggle: React.FC<DocumentTypeToggleProps> = ({
  documentType,
  onToggle,
}) => {
  const { t } = useTranslation();

  const toggleData = [
    {
      label: (
        <Center style={{ gap: 4 }}>
          <IconFileCv style={{ width: rem(16), height: rem(16) }} />
          <span>{t('view.resumeTab')}</span>
        </Center>
      ),
      value: 'resume',
    },
    {
      label: (
        <Center style={{ gap: 4 }}>
          <IconMailSpark style={{ width: rem(16), height: rem(16) }} />
          <span>{t('view.coverLetterTab')}</span>
        </Center>
      ),
      value: 'coverLetter',
    },
  ];

  return (
    <SegmentedControl
      color="blue"
      radius="xl"
      size="sm"
      value={documentType}
      onChange={onToggle}
      data={toggleData}
    />
  );
};
