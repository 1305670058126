import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import Api from '../Api';
import { ApiRoutes } from '../ApiRoutes';
import { IUserProfile } from './Profile';

export function isProfileEmpty() {
  const { data: profile } = useProfileQuery();

  return (
    !profile?.sections ||
    profile.sections.length === 0 ||
    profile.sections[0].title.trim() === ''
  );
}

export function useProfileCompleteness() {
  const { data: profile } = useProfileQuery();

  if (!profile) {
    return 0;
  }

  let completeness = 0;

  if (profile.firstName && profile.firstName.trim().length > 0) {
    completeness += 3;
  }
  if (profile.lastName && profile.lastName.trim().length > 0) {
    completeness += 3;
  }
  if (profile.phone && profile.phone.trim().length > 0) {
    completeness += 3;
  }
  if (profile.address && profile.address.trim().length > 0) {
    completeness += 3;
  }

  if (profile.intro) {
    if (profile.intro.length > 100) {
      completeness += 25;
    } else if (profile.intro.length > 0) {
      completeness += 15;
    }
  }

  if (profile.sections) {
    if (profile.sections.length > 3) {
      completeness += 63;
    } else {
      completeness += profile.sections.length * 21;
    }
  }

  return completeness;
}

export const QUERY_KEY_PROFILE = ['profile'];

export const useProfileMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (updatedProfile: Partial<IUserProfile>) =>
      Api.ky
        .put(ApiRoutes.Profile, { json: updatedProfile })
        .json<IUserProfile>(),
    onSuccess: (data) => queryClient.setQueryData(QUERY_KEY_PROFILE, data),
  });
};

export const useProfileQuery = (
  options?: Partial<UseQueryOptions<IUserProfile>>,
) =>
  useQuery({
    queryKey: QUERY_KEY_PROFILE,
    queryFn: () => Api.ky.get(ApiRoutes.Profile).json<IUserProfile>(),
    ...options,
  });
