import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import Api from '../Api';
import { ApiRoutes } from '../ApiRoutes';
import { IResume, IResumeHistory } from '../models/Resume';

interface IHistoryResponse {
  history: IResumeHistory[];
}

type ICreateHistoryDto = Pick<IResumeHistory, 'date' | 'tag' | 'notes'>;

const QUERY_KEY_RESUME_HISTORY = ['resume-history'];

const isTag = (item: string | undefined): item is string => {
  return !!item;
};

const filterValues = (dto: ICreateHistoryDto) => ({
  date: dayjs(dto.date).toString(),
  tag: dto.tag || undefined,
  notes: dto.notes || undefined,
});

export const useAddHistoryMutation = (resumeId: IResume['id'] = 'UNKNOWN') => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (newHistory: ICreateHistoryDto) =>
      Api.ky
        .post(`${ApiRoutes.Resumes}/${resumeId}/history`, {
          json: filterValues(newHistory),
        })
        .json<IResume>(),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [...QUERY_KEY_RESUME_HISTORY, resumeId],
      }),
  });
};

export const useDeleteHistoryMutation = (
  resumeId: IResume['id'] = 'UNKNOWN',
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (historyId: IResumeHistory['id']) =>
      Api.ky.delete(`${ApiRoutes.Resumes}/${resumeId}/history/${historyId}`),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [...QUERY_KEY_RESUME_HISTORY, resumeId],
      }),
  });
};

export const useResumeHistoryQuery = (
  resumeId: IResume['id'],
  setTags: (tags: string[]) => void,
  defaultTags: string[],
) =>
  useQuery({
    queryKey: [...QUERY_KEY_RESUME_HISTORY, resumeId],

    queryFn: () =>
      Api.ky
        .get(`${ApiRoutes.Resumes}/${resumeId}/history`)
        .json<IHistoryResponse>()
        .then((res) => {
          const uniqueTags = [
            ...new Set<string>([
              ...defaultTags,
              ...res.history.map((h) => h.tag).filter(isTag),
            ]),
          ];
          uniqueTags.sort((a, b) => a.localeCompare(b));
          setTags(uniqueTags);

          res.history.sort((a, b) => dayjs(b.date).diff(dayjs(a.date)));

          return res;
        }),
  });
