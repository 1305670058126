import { Container, Stack } from '@mantine/core';
import React from 'react';
import { IResume } from '../../models/Resume';
import FancyInfoBox from './FancyInfoBox';
import { InstructionsSection } from './InstructionsSection';
import { ResumeBadgesGroup } from './ResumeBadgesGroup';
import { SettingsSection } from './SettingsSection';
import { TipsSection } from './TipsSection';

export const ResumeInfoView: React.FC<{ resume: IResume; hidden: boolean }> = ({
  resume,
  hidden,
}) => {
  return (
    <Container size="md" mb="xl" pb="xl">
      <Stack gap="4rem">
        <ResumeBadgesGroup resume={resume} />

        {resume.suggestion?.explanation && (
          <FancyInfoBox infoText={resume.suggestion.explanation} />
        )}

        {Array.isArray(resume.suggestion?.tips) &&
          resume.suggestion.tips.length > 0 && (
            <TipsSection tips={resume.suggestion.tips} hidden={hidden} />
          )}

        <InstructionsSection resume={resume} />

        <SettingsSection resume={resume} />
      </Stack>
    </Container>
  );
};
