import {
  ActionIcon,
  Button,
  Group,
  List,
  Modal,
  Paper,
  Stack,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconPencil, IconPlus, IconTrash } from '@tabler/icons-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IResumeWithSuggestion } from '../../models/Resume';
import { useUpdateResumeJsonMutation } from '../../queries/ResumeQueries';
import { SubscribeCTAOverlay } from '../SubscribeCTAOverlay/SubscribeCTAOverlay';

export const Achievements: React.FC<{
  resume: IResumeWithSuggestion;
  hidden: boolean;
}> = ({ resume, hidden }) => {
  const achievements = resume.suggestion.achievements;
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);

  const form = useForm({
    initialValues: {
      achievements:
        achievements.length > 0 ? achievements : [{ name: '', issuer: '' }],
    },
    validate: {
      achievements: {
        name: (value) =>
          !value ? t('resume.achievements.achievementNameRequired') : null,
        issuer: (value) =>
          !value ? t('resume.achievements.achievementIssuerRequired') : null,
      },
    },
  });

  const updateResumeMutation = useUpdateResumeJsonMutation(resume.id);

  const handleSave = () => {
    if (form.isValid()) {
      const updatedResumeJson = {
        ...resume.suggestion,
        achievements: form.values.achievements,
      };
      updateResumeMutation.mutate(updatedResumeJson, {
        onSuccess: () => {
          setOpened(false);
        },
      });
    }
  };

  const handleAchievementChange = (
    index: number,
    field: string,
    value: string,
  ) => {
    form.setFieldValue(`achievements.${index}.${field}`, value);
    form.validateField(`achievements.${index}.${field}`);
  };

  const addAchievement = () => {
    form.setFieldValue('achievements', [
      ...form.values.achievements,
      { name: '', issuer: '' },
    ]);
  };

  const removeAchievement = (index: number) => {
    if (form.values.achievements.length > 1) {
      form.setFieldValue(
        'achievements',
        form.values.achievements.filter((_, idx) => idx !== index),
      );
    }
  };

  return (
    <Stack>
      <Group>
        <Title order={3}>{t('resume.achievements.title')}</Title>
        <ActionIcon
          variant="subtle"
          size="sm"
          onClick={() => {
            setOpened(true);
          }}
          disabled={hidden}
        >
          <IconPencil />
        </ActionIcon>
      </Group>

      <Paper p="md">
        <div style={{ position: 'relative' }}>
          <List size="md">
            {achievements.map((achievement, idx) => (
              <List.Item key={idx}>
                {achievement.name}
                {achievement.issuer && ` (${achievement.issuer})`}{' '}
              </List.Item>
            ))}
          </List>
          <SubscribeCTAOverlay
            enabled={hidden}
            style={{
              marginLeft: '1em',
              marginRight: '-0.5em',
              marginTop: '1.5em',
            }}
          />
        </div>
      </Paper>

      <Modal
        opened={opened}
        onClose={() => {
          setOpened(false);
        }}
        title={t('resume.achievements.editAchievements')}
        centered
        size="xl"
      >
        <form onSubmit={form.onSubmit(handleSave)}>
          <Stack gap="md">
            {form.values.achievements.map((achievement, index) => (
              <Group key={index} align="flex-start" mt="xs">
                <TextInput
                  label={t('resume.achievements.achievementName')}
                  value={achievement.name}
                  onChange={(event) => {
                    handleAchievementChange(
                      index,
                      'name',
                      event.currentTarget.value,
                    );
                  }}
                  error={form.errors[`achievements.${index}.name`]}
                  onBlur={() =>
                    form.validateField(`achievements.${index}.name`)
                  }
                  style={{ flex: 1 }}
                />
                <TextInput
                  label={t('resume.achievements.achievementIssuer')}
                  value={achievement.issuer}
                  onChange={(event) => {
                    handleAchievementChange(
                      index,
                      'issuer',
                      event.currentTarget.value,
                    );
                  }}
                  error={form.errors[`achievements.${index}.issuer`]}
                  onBlur={() =>
                    form.validateField(`achievements.${index}.issuer`)
                  }
                  style={{ flex: 1 }}
                />
                {form.values.achievements.length > 1 && (
                  <ActionIcon
                    color="red"
                    onClick={() => {
                      removeAchievement(index);
                    }}
                  >
                    <IconTrash />
                  </ActionIcon>
                )}
              </Group>
            ))}
            <Button mt="md" leftSection={<IconPlus />} onClick={addAchievement}>
              {t('resume.achievements.addAchievement')}
            </Button>
          </Stack>
          <Group justify="flex-end" mt="md" gap="sm">
            <Button
              variant="outline"
              onClick={() => {
                setOpened(false);
              }}
            >
              {t('labels.cancel')}
            </Button>
            <Button type="submit" disabled={!form.isValid()}>
              {t('labels.save')}
            </Button>
          </Group>
        </form>
      </Modal>
    </Stack>
  );
};
