import { Anchor, Image, useComputedColorScheme } from '@mantine/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { AppRouteURL } from '../../AppRouteURL';
import bannerBlackBG from './logo-banner-blackbg.svg';
import bannerWhiteBG from './logo-banner-whitebg.svg';
import squarePictogram from './logo-square-pictogram.svg';

export const LogoType = {
  Pictogram: {
    dark: squarePictogram,
    light: squarePictogram,
  },
  Square: {
    dark: squarePictogram,
    light: squarePictogram,
  },
  Banner: {
    dark: bannerBlackBG,
    light: bannerWhiteBG,
  },
};

export const Logo: React.FC<{
  h?: number | string;
  w?: number | string;
  fit?: React.CSSProperties['objectFit'];
  type: keyof typeof LogoType;
}> = ({ h = 84, w = 'auto', type, fit = 'contain' }) => {
  const colorScheme = useComputedColorScheme();
  return (
    <Anchor component={Link} to={AppRouteURL.home}>
      <Image
        src={LogoType[type][colorScheme]}
        h={h}
        w={w === 'auto' && type === 'Pictogram' ? h : w}
        fit={fit}
        alt="ResuFit Logo"
      />
    </Anchor>
  );
};
